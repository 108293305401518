import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@zeiss/zui';
import '@zeiss/zui-icons';
import {
    themeZbdsBase,
    themeZbdsLight,
    themeZbdsTranslucent,
    registerTheme,
    registerTypefaces,
    setAppTheme,
} from '@zeiss/zui-themes';
import { AppModule } from './app/app.module';
import { isDevMode } from '@angular/core';
import {
    akitaDevtools,
    enableAkitaProdMode,
    persistState,
} from '@datorama/akita';
import { enableProdMode } from '@angular/core';

// Zui web-components
registerTheme(themeZbdsBase);
registerTheme(themeZbdsLight);
registerTheme(themeZbdsTranslucent);
setAppTheme('zbds-light');
registerTypefaces('/fonts');

if (isDevMode()) {
    akitaDevtools({ name: 'SMT-PDX' });
} else {
    enableProdMode();
    enableAkitaProdMode();
}
persistState({ storage: localStorage, include: ['authStore'] });
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-console
    .catch((err: any) => console.error(err));

// Start akita Dev-Tools to be able to use Redux Dev-Tools in Dev-Mode
