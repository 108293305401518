import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './common/components/login/login.component';
import { AuthGuard } from './common/guards/auth.guard';
import { DashboardComponent } from './main/components/dashboard/dashboard.component';
import { OrderSearchComponent } from './main/components/orders-search/order-search.component';
import { UnauthorizedComponent } from './common/components/error-pages/unauthorized/unauthorized.component';

const routes: Routes = [
    {
        component: LoginComponent,
        path: 'login',
    },
    {
        component: DashboardComponent,
        path: 'dashboard',
        canActivate: [AuthGuard],
    },
    {
        component: OrderSearchComponent,
        path: 'orders/order/:id',
        canActivate: [AuthGuard],
    },
    {
        component: OrderSearchComponent,
        path: 'orders',
        canActivate: [AuthGuard],
    },
    {
        component: UnauthorizedComponent,
        path: 'unauthorized'
    },
    {
        redirectTo: 'login',
        path: '',
        pathMatch: 'full',
    },
    {
        redirectTo: 'login',
        path: '**',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
