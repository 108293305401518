import { ProductCategory } from "../models/order.type";

/* eslint-disable @typescript-eslint/typedef */
export const PRODUCT_CATEGORY_TRANSLATION_CONFIG: {
    translationKeyMap: { [key in ProductCategory]: string };
    translationPrefix: string;
} = {
    translationKeyMap: {
        0: 'NONE',
        1: 'ABSTIMMRING',
        2: 'ERDTEIL',
        3: 'OPTIKRING',
        4: 'TBO',
        5: 'ROHLING',
        6: 'TARGET'
    },
    translationPrefix: 'PRODUCT_CATEGORY.VALUES.',
};
