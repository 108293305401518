import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment';
import { AuthService } from '../../../services/auth.service';
import { AuthStore } from 'src/app/main/store/auth/auth.store';
import { User } from '../../../models/user.type';

@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {
    public subscriptions: Array<Subscription> = [];

    public versionNumber: string = '';

    public user!: User;

    public isUserInfoPopUpVisible: boolean = false;
    public isReleaseNotesPopUpVisible: boolean = false;

    constructor(private readonly authService: AuthService, readonly authStore: AuthStore) { }

    ngOnInit(): void {
        this.versionNumber = ENVIRONMENT.versionNumber;
        this.user = this.authStore.getValue().user;
    }

    public logout() {
        this.authStore.reset();
        this.authService.logout();
    }

    public openUserInfo(): void {
        if (Object.keys(this.user).length)
            this.isUserInfoPopUpVisible = true;
    }

    public closeUserInfo(): void {
        this.isUserInfoPopUpVisible = false;
    }

    public openReleaseNotes(): void {
        if (Object.keys(this.user).length)
            this.isReleaseNotesPopUpVisible = true;
    }

    public closeReleaseNotes(): void {
        this.isReleaseNotesPopUpVisible = false;
    }
}
