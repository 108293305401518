import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    
    constructor(private loggingService: LoggingService) {
        super();
    }

    public override handleError(error: Error) {
        this.loggingService.logException(error);
    }
}
