import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { catchError, filter, from, map, Observable, of, switchMap } from 'rxjs';
import { ENVIRONMENT } from '../../../environments/environment';
import { AuthHttpService } from './auth-http.service';
import { User } from '../models/user.type';
import { AuthStore } from 'src/app/main/store/auth/auth.store';
import { resetStores } from '@datorama/akita';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    get idToken(): string {
        return this.oauthService.getIdToken();
    }

    constructor(
        readonly oauthService: OAuthService,
        readonly router: Router,
        readonly authHttpService: AuthHttpService,
        readonly authStore: AuthStore
    ) {}

    /**
     * Configures the OAuth2 service and calls tryLogin
     *
     * @returns {Observable<boolean>} Observable with a success flag
     */

    public tryZeissIdLogin(): Observable<boolean> {
        if (this.hasValidToken()) {
            return of(true);
        }
        return this.loadDiscoveryDocument().pipe(
            switchMap(async (discoveryDoc: OAuthSuccessEvent) => {
                this.configureSessionChecks(discoveryDoc);
                try {
                    await this.oauthService.tryLoginCodeFlow();
                    return true;
                } catch {
                    return false;
                }
            })
        );
    }

    /**
     * Checks if there is a valid Id-Token in storage
     *
     * @returns {boolean} true when a valid Id-Token is stored in the localStorage
     */
    public hasValidToken(): boolean {
        return this.oauthService.hasValidIdToken();
    }

    /**
     * Checks if there is a valid Id-Token in storage. If the token is not Valid
     * and a refresh_token is present, it will try to refresh the ID-Token.
     *
     * @returns {Observable<boolean>} Observable that emit true when
     * there is a Valid token in Storage, wether it is refreshed or was already valid
     */
    public tryRefreshToken(): Observable<boolean> {
        if (this.oauthService.getRefreshToken()) {
            return this.refreshToken();
        }
        return of(false);
    }

    /**
     * Facade for OAuth  Logout function
     */
    public logout(): void {
        sessionStorage.clear();
        resetStores();
        this.oauthService.logOut();
    }

    /**
     * Facade for OAuth Login function
     */
    public login(): void {
        this.oauthService.initCodeFlow();
    }

    public refreshToken(): Observable<boolean> {
        return this.loadDiscoveryDocument().pipe(
            switchMap(() => {
                return from(this.oauthService.refreshToken()).pipe(
                    map(() => true),
                    catchError(() => of(false))
                );
            })
        );
    }

    private loadDiscoveryDocument(): Observable<OAuthSuccessEvent> {
        return from(this.oauthService.loadDiscoveryDocument(ENVIRONMENT.zeissIdHost));
    }

    private configureSessionChecks(discoveryDoc: OAuthSuccessEvent): void {
        this.oauthService.sessionChecksEnabled = true;
        this.oauthService.sessionCheckIntervall = 5000;
        this.oauthService.sessionCheckIFrameUrl = discoveryDoc.info.discoveryDocument.authorization_endpoint;
    }

    public tokenEventsLog(): void {
        this.oauthService.events
            // any is used to get the attribute "info" -> it is needed to avoid an endless loop
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .pipe(filter((event: any) => event.type === 'token_expires' && event.info === 'id_token'))
            .subscribe(() => this.tryRefreshToken().subscribe());
    }

    public configure(authConfig: AuthConfig): void {
        this.oauthService.configure(authConfig);
    }

    public loginUser(): void {
        this.authStore.setLoading(true);
        this.authHttpService.loginUser().subscribe({
            next: (user: User) => {
                this.authStore.update({
                    user: user,
                });
            },
            error: () => {
                this.authStore.reset();
                this.router.navigate(["/unauthorized"])
            },
            complete: () => this.authStore.setLoading(false),
        });
    }
}
