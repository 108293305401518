import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { OrderSearch, OrderStateMapping, ProductCategory } from 'src/app/common/models/order.type';
import { OrderService } from 'src/app/main/services/order/order.service';
import { OrderQuery } from 'src/app/main/store/order/order.query';

@Component({
    selector: 'app-order-search-bar',
    templateUrl: './order-search-bar.component.html',
    styleUrls: ['./order-search-bar.component.scss'],
})
export class OrderSearchBarComponent {
    @Input() disableSearchButton = false;
    @Output() searchRequestSubmit$: EventEmitter<OrderSearch> = new EventEmitter<OrderSearch>();

    public readonly ORDER_STATE = {
        InProduction: 'InProduction',
        Shipped: 'Shipped',
        Ordered: 'Ordered',
        Rejected: 'Rejected',
    };

    public searchForm: FormGroup;
    public statusDropdownData!: DataSource<string, string>;
    public productCategories: string[] = Object.keys(ProductCategory).filter((key: string) => isNaN(Number(key)) && key !== 'None');
    public ordererDepartments: string[] = ["Montage", "Justage", "Wetzlar", "OPK"];
    private orderStateTranslationPrefix: string = 'ORDERS_OVERVIEW.FORM.STATE.VALUES.';
    public orderStates: OrderStateMapping[] = [
                { value: 'InProduction', displayName: this.orderStateTranslationPrefix + 'IN_PRODUCTION' },
                { value: 'Shipped', displayName: this.orderStateTranslationPrefix + 'SHIPPED' },
                { value: 'Ordered', displayName: this.orderStateTranslationPrefix + 'ORDERED' },
                { value: 'Rejected', displayName: this.orderStateTranslationPrefix + 'REJECTED' }
            ];
    public selectedProductCategories!: string[];
    public isDropDownBoxOpened!: boolean;

    constructor(private formBuilder: FormBuilder, private orderQuery: OrderQuery, readonly orderService: OrderService) {
        this.searchForm = this.getFormGroup();
    }

    public submitSearch(): void {
        this.searchRequestSubmit$.next(this.searchForm.value as OrderSearch);
    }

    public clearAllFormControls(): void {
        this.searchForm.setValue({
            complainedOrders: false,
            set: '',
            ordererDepartments: [],
            states: [],
            desiredDeliveryDate: {
                from: null,
                to: null,
            },
            productCategories: []
        });
    }

    private getFormGroup(): FormGroup {
        // eslint-disable-next-line jsdoc/require-jsdoc
        function getDefaultFilter() {
            const from: Date = new Date();
            from.setHours(0, 0, 0, 0);
            from.setDate(from.getDate() - 1);
 
            const to: Date = new Date();
            
            return {
                desiredDeliveryDate: {
                    from: from,
                    to: to
                }
            };
        }
        
        const { complainedOrders, set, ordererDepartments, states, productCategories } = this.orderQuery.searchQuery || {};
        const { desiredDeliveryDate } = this.orderQuery.searchQuery || getDefaultFilter();

        return this.formBuilder.group({
            complainedOrders: [complainedOrders || false],
            set: [set || ''],
            ordererDepartments: [ordererDepartments || []],
            states: [states || []],
            desiredDeliveryDate: this.formBuilder.group({
                from: desiredDeliveryDate?.from ? new Date(desiredDeliveryDate.from) : null,
                to: desiredDeliveryDate?.to ? new Date(desiredDeliveryDate.to) : null,
            }),
            productCategories: [productCategories || []]
        });
    }
}
