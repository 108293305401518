import { Pipe, PipeTransform } from '@angular/core';
import { OrderIdWithDeliveryDate } from '../models/order.type';

@Pipe({
    name: 'newDeliveryDate',
})
export class NewDeliveryDatePipe implements PipeTransform {

    transform(orderId: string, orderIdWithDeliveryDate: OrderIdWithDeliveryDate[]): Date | string {
        if (!orderId || !orderIdWithDeliveryDate) return '';

        let result = orderIdWithDeliveryDate.find((order: OrderIdWithDeliveryDate) => order.orderId === orderId);

        if (result) return result.deliveryDate;

        return '';
    }
}
