<div *ngIf="recipeData" class="context-data-container">
    <div class="field-container">
        <div class="label">
            <label for="holeCount"> {{ 'ORDER_DETAIL.RECIPE_CARD.HOLE_COUNT' | translate }}: </label>
        </div>
        <div id="holeCount" class="field">{{ recipeData.holeNumber }}</div>
    </div>

    <div class="field-container">
        <div class="label">
            <label for="ringDiameter"> {{ 'ORDER_DETAIL.RECIPE_CARD.RING_DIAMETER' | translate }}: </label>
        </div>
        <div id="ringDiameter" class="field">{{ recipeData.ringDiameter }} {{ recipeData.ringDiameterUnit |
            unitOfMeasure }}</div>
    </div>
    <table class="ring-thickness">
        <tr>
            <th class="text">#</th>
            <th class="text">{{ 'ORDER_DETAIL.RECIPE_CARD.REQUIRED_RING_DEPTH' | translate }}</th>
            <th class="text">{{ 'ORDER_DETAIL.RECIPE_CARD.ACTUAL_RING_DEPTH' | translate }}</th>
        </tr>
        <tr *ngFor="let ringThickness of recipeData.ringThicknessTarget; let i = index">
            <td class="text">{{ 'ORDER_DETAIL.RECIPE_CARD.VALUE' | translate }} {{ i + 1 }}</td>
            <td>
                <span *ngIf="isDelta(order)">&#916;</span>
                {{ ringThickness }} {{ recipeData.ringThicknessTargetUnit | unitOfMeasure }}
            </td>
            <td class="input-field">
                <dx-number-box name="number" [step]="0.1" [showSpinButtons]="true"
                    [disabled]="!this.canChangeRingThicknesses" [(ngModel)]="actualRingThicknesses[i]"
                    class="number-box" valueChangeEvent="keyup"></dx-number-box>
                {{ recipeData.ringDiameterUnit | unitOfMeasure }}
            </td>
        </tr>
    </table>
    <dx-button (click)="saveRingThicknesses()" class="submit-button" [disabled]="disableSubmitButton()">
        {{ 'ORDER_DETAIL.CHANGE' | translate }}
    </dx-button>
</div>

<div class="loading-message-container" *ngIf="!recipeData">
    <dx-load-indicator height="20" width="20"></dx-load-indicator>
    <p class="loading-message">{{ 'ORDER_DETAIL.RECIPE_CARD.LOADING' | translate }}</p>
</div>