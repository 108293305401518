import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/main/store/auth/auth.store';
import { User } from '../../../models/user.type';

@Component({
    selector: 'app-user-information',
    templateUrl: './user-information.component.html',
    styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent implements OnInit {

    public user!: User;

    constructor(readonly authStore: AuthStore) {}

    ngOnInit(): void {
        this.user = this.authStore.getValue().user;
    }
}
