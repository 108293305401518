import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ToastState } from 'src/app/common/models/toastState.type';

/**
 * Create the initial OrderStore state
 * 
 * @returns {OrderState} initial State
 */
export function createInitialToastState(): ToastState{
    return {
        successToast: {
            active: false
        },
        errorToast: {
            active: false,
        }
    };
}

@StoreConfig({name: 'toastStore'})
@Injectable({ providedIn: 'root' })
export class ToastStore extends Store<ToastState> {
    constructor() {
        super(createInitialToastState());
    }
}