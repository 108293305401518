import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthState } from 'src/app/common/models/authState.type';
import { User } from 'src/app/common/models/user.type';

/**
 * Create the initial AuthStore state
 * 
 * @returns {AuthState} initial State
 */
export function createInitialAuthState(): AuthState {
    return {
        user: {} as User
    };
}

@StoreConfig({name: 'authStore'})
@Injectable({ providedIn: 'root' })
export class AuthStore extends Store<AuthState> {
    constructor() {
        super(createInitialAuthState());
    }
}