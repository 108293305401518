<zui-page-header>
    <zui-title-stack size="m">
        <h1>{{ 'ORDERS_OVERVIEW.TITLE' | translate }}</h1>
    </zui-title-stack>
</zui-page-header>

<div class="styled-border search-container">
    <app-order-search-bar (searchRequestSubmit$)="submitSearch($event)" [disableSearchButton]="loadingData"></app-order-search-bar>

    <ng-container *ngIf="(searchResults.length > 0 && !loadingData) || selectedOrder">
        <app-order-data-grid
            (orderRowClick$)="onOrderRowClickEvent($event)"
            (refreshSearchResults$)="fetchSearchResults()"
            [orderSearchResults]="searchResults"
            [orderIdForDetailsView]="selectedOrder"
        ></app-order-data-grid>
    </ng-container>
        <div
            *ngIf="searchResults.length === 0 || loadingData"
            class="search-message"
        >
            <zui-inline-message *ngIf="!loadingData && !noDataFound">
                {{ 'ORDERS_OVERVIEW.NO_SEARCH_RESULT' | translate }}
                <zui-icon-alerts-ewi-info slot="icon"></zui-icon-alerts-ewi-info>
            </zui-inline-message>
            <zui-inline-message *ngIf="loadingData">
                {{ 'ORDERS_OVERVIEW.LOADING_DATA' | translate }}
                <zui-progress-ring
                    slot="icon"
                    emphasis="highlight"
                    mode="activity"
                ></zui-progress-ring>
            </zui-inline-message>
            <zui-inline-message *ngIf="!loadingData && noDataFound && !searchResults.length">
                {{ 'ORDERS_OVERVIEW.NO_DATA' | translate }}
                <zui-icon-alerts-ewi-info slot="icon"></zui-icon-alerts-ewi-info>
            </zui-inline-message>
        </div>
</div>
