import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Order, OrderSearch } from 'src/app/common/models/order.type';
import { OrdersSearchService } from './services/orders-search.service';
import { LoadOptions } from 'devextreme/data';

@Component({
    selector: 'app-orders-search',
    templateUrl: './order-search.component.html',
    styleUrls: ['./order-search.component.scss'],
})
export class OrderSearchComponent implements OnDestroy, OnInit {
    public searchResults: Order[] = [];
    public loadingData: boolean = false;
    public noDataFound: boolean = false;

    private loadOptions: LoadOptions = { filter: [], skip: 0, take: 10 };
    private orderSearch?: OrderSearch;
    private destroySearch$ = new Subject<void>();
    private ordererDepartmentMapping: Record<string, string[]> = {
        "Montage": ["SMT-OIDM", "SMT-OIDK"],
        "Justage": ["SMT-OIDI", "SMT-OIDJ"],
        "Wetzlar": ["W"],
        "OPK": ["OPK"]
    };
    private subscription!: Subscription;
    public selectedOrder: string | undefined;

    constructor(readonly orderSearchService: OrdersSearchService, readonly router: Router, readonly route: ActivatedRoute) {}
    
    ngOnInit(): void {
        this.subscription = this.route.paramMap.subscribe((param: ParamMap) => {
            this.selectedOrder = param.get('id')!;
        });
    }

    ngOnDestroy(): void {
        this.destroySearch$.next();
        this.subscription.unsubscribe();
    }

    public submitSearch(orderSearch: OrderSearch): void {
        this.loadOptions.filter = [];
        this.orderSearch = orderSearch;

        if (orderSearch.desiredDeliveryDate?.from) {
            this.loadOptions.filter.push(['body.deliveryDate', '>=', orderSearch.desiredDeliveryDate.from]);
        }

        if (orderSearch.desiredDeliveryDate?.to) {
            orderSearch.desiredDeliveryDate.to.setHours(24, 0, 0, -1);
            this.loadOptions.filter.push(['body.deliveryDate', '<=', orderSearch.desiredDeliveryDate.to]);
        }

        if (orderSearch.complainedOrders) this.loadOptions.filter.push(['childId', null], ['parentId', "<>", null]);

        if (orderSearch.set) this.loadOptions.filter.push(['body.set', 'contains', orderSearch.set]);

        if (orderSearch.ordererDepartments && orderSearch.ordererDepartments.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const ordererDepartmentsFilter: any = [];
            orderSearch.ordererDepartments.forEach((ordererDepartment: string) => {
                this.ordererDepartmentMapping[ordererDepartment].forEach((mappedOrdererDepartments: string) => {
                    ordererDepartmentsFilter.push(['body.ordererDepartment', 'contains', mappedOrdererDepartments], 'or');
                });
            });
            this.loadOptions.filter.push([ordererDepartmentsFilter]);
        }

        if (orderSearch.states && orderSearch.states.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const statesFilter: any = [];
            orderSearch.states.forEach((state: string) => {
                statesFilter.push(['body.details.state', '=', state], 'or');
            });
            this.loadOptions.filter.push([statesFilter]);
        }

        if (orderSearch.productCategories && orderSearch.productCategories.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const productCategoriesFilter: any = [];
            orderSearch.productCategories.forEach((productCategory: string) => {
                productCategoriesFilter.push(['body.productCategory', '=', productCategory], 'or');
            });
            this.loadOptions.filter.push([productCategoriesFilter]);
        }

        this.fetchSearchResults();
    }

    public onOrderRowClickEvent(rowData: Order): void {
        const id: string = rowData.id;
        this.router.navigate(['/orders/order', id]);
    }

    public fetchSearchResults(): void {
        this.loadingData = true;
        this.noDataFound = false;
        this.searchResults = [];
        this.destroySearch$.next();

        if (this.orderSearch) {
            try {
                this.orderSearchService
                    .fetchSearchResults(this.orderSearch, this.loadOptions)
                    .pipe(takeUntil(this.destroySearch$))
                    .subscribe((searchResults: Order[]) => {
                        if (searchResults.length === 0) {
                            this.noDataFound = true;
                        }
                        this.searchResults = searchResults;
                        this.loadingData = false;
                    });
            } catch (error) {
                this.noDataFound = true;
                this.loadingData = false;
            }
        }
    }
}
