import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReleaseNotesHttpService } from './release-notes-http.service';
import { ReleaseNotes } from '../../models/releaseNotes.type';
import { AuthStore } from 'src/app/main/store/auth/auth.store';

@Injectable({
    providedIn: 'root',
})
export class ReleaseNotesService {

    constructor(
        readonly releaseNotesHttpService: ReleaseNotesHttpService,
        readonly authStore: AuthStore
    ) {}

    /**
     * Returns the ReleaseNotesResponse
     *
     * @returns {Observable<ReleaseNotes[]>} Returns the ReleaseNotesResponse
     */
    public getReleaseNotes(): Observable<ReleaseNotes[]> {
        return this.releaseNotesHttpService.getReleaseNotes(this.authStore.getValue().user.language);
    }
}
