import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PRODUCT_CATEGORY_TRANSLATION_CONFIG } from '../configuration/product-category-translation';
import { ProductCategory } from '../models/order.type';

@Pipe({
    name: 'productCategoryTranslation',
})
export class ProductCategoryTranslationPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: string): string {
        if (!value) return '';

        const translationKey: string =
            PRODUCT_CATEGORY_TRANSLATION_CONFIG.translationPrefix +
            PRODUCT_CATEGORY_TRANSLATION_CONFIG.translationKeyMap[value as unknown as ProductCategory];
        return this.translateService.instant(translationKey);
    }
}
