import { Injectable } from '@angular/core';
import { map, Observable, timer, filter } from 'rxjs';
import {
    MeasurementData,
    MeasurementDocument,
    Order,
    OrderDetails,
    RecipeData,
} from 'src/app/common/models/order.type';
import { OrderService } from 'src/app/main/services/order/order.service';
import { OrderQuery } from 'src/app/main/store/order/order.query';

@Injectable({
    providedIn: 'root',
})
export class OrderDetailService {

    constructor(
        private orderService: OrderService,
        private orderQuery: OrderQuery
    ) {}

    public fetchOrderDetail(orderId: string): Observable<Order> {
        this.orderService.selectedOrder(orderId);
        return this.orderQuery.detailedOrder$.pipe(
            filter((order: Order | undefined): order is Order => !!order)
        );
    }

    public fetchOrderData(): Observable<Order> {
        return this.orderQuery.detailedOrder$.pipe(
            filter((order: Order | undefined): order is Order => !!order)
        );
    }
    
    public fetchRecipeData(): Observable<RecipeData> {
        return this.orderQuery.detailedOrder$.pipe(
            filter((order: Order | undefined): order is Order => !!order),
            map((order: Order) => order.recipeData)
        );
    }

    public fetchMeasurementData(): Observable<MeasurementData> {
        return this.orderQuery.detailedOrder$.pipe(
            filter((order: Order | undefined): order is Order => !!order),
            map((order: Order) => order.measurementData)
        );
    }

    public fetchMeasurementDocuments(): Observable<MeasurementDocument[]> {
        return this.orderQuery.measurementDocuments$.pipe(
            map((documents: MeasurementDocument[]) => documents)
        );
    }

    public updateChangedOrder(orderId: string, orderDetails: OrderDetails): Observable<boolean> {
        this.orderService.updateOrderDetails(orderId, orderDetails);
        return timer(3000).pipe(map(() => true));
    }
}
