import { Component, OnInit } from '@angular/core';
import { ReleaseNotes } from 'src/app/common/models/releaseNotes.type';
import { ReleaseNotesService } from 'src/app/common/services/release-notes-service/release-notes.service';

@Component({
    selector: 'app-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent implements OnInit {

    public releaseNotes!: ReleaseNotes[];

    constructor(readonly releaseNotesService: ReleaseNotesService) { }

    ngOnInit(): void {
        this.releaseNotesService.getReleaseNotes().subscribe(
            (releaseNotes: ReleaseNotes[]) => {
                this.releaseNotes = releaseNotes;
                this.releaseNotes.sort((a: ReleaseNotes, b: ReleaseNotes) => {
                    return new Date(b.date).getTime() - new Date(a.date).getTime();
                });
            }
        );
    }
}
