/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    host: {
        class: 'h-100 w-100',
    },
})
export class LoginComponent implements OnInit {
    constructor(private readonly authService: AuthService, readonly router: Router) {}

    public ngOnInit(): void {
        if (this.authService.hasValidToken()) {
            this.router.navigate(['/orders']);
        }
    }

    public login() {
        this.authService.login();
    }
}
