import { Pipe, PipeTransform } from "@angular/core";
import { UnitOfMeasure } from "../models/order.type";

@Pipe({
  name: 'unitOfMeasure',
})
export class UnitOfMeasurePipe implements PipeTransform {
  transform(unitOfMeasure: UnitOfMeasure) {
        return UnitOfMeasure[unitOfMeasure];
    }
}
