<table>
   <tr>
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.NAME' | translate }}</th>
      <td>{{ user.firstName }} {{ user.lastName }}</td>
   </tr>
   <tr>
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.EMAIL' | translate }}</th>
      <td>{{ user.eMail }}</td>
   </tr>
   <tr>
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.LANGUAGE' | translate }}</th>
      <td>{{ user.language }}</td>
   </tr>
   <tr>
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.ACCOUNT_ID' | translate }}</th>
      <td>{{ user.accountID }}</td>
   </tr>
   <tr>
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.ORGANIZATION' | translate }}</th>
      <td>{{ user.organizationName }}</td>
   </tr>
   <tr>
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.ORGANIZATION_ID' | translate }}</th>
      <td>{{ user.organizationId }}</td>
   </tr>
   <tr *ngIf="user.memberOfGroups">
      <th>{{ 'HEADERBAR.USERINFORMATION.TABLEHEADER.USER_ROLE' | translate }}</th>
         <td>
            <div *ngFor="let group of user.memberOfGroups">
               {{ 'HEADERBAR.USERINFORMATION.USER_ROLES.' + group | translate }}
            </div>
         </td>
   </tr>
</table>