import { Injectable } from '@angular/core';
import { OrderStore } from '../../store/order/order.store';
import {
    MeasurementDocument,
    Order,
    OrderSearch,
    MeasurementData,
    OrderDetails,
    ComplaintResponse,
    GridLoadOrderResult,
    ComplaintRequest,
    OrderDeliveryDateBulkResponse,
    OrderDeliveryDateBulkRequestDryRun,
    OrderIdWithDeliveryDate,
    OrderDeliveryDateBulkRequestCommit,
    BulkEditOrder
} from 'src/app/common/models/order.type';
import { OrderHttpService } from './order-http.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { LoadOptions } from 'devextreme/data';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(readonly orderStore: OrderStore, private orderHttpService: OrderHttpService, private toastService: ToastService) { }

    public fetchOrderResults(searchQuery: OrderSearch, loadOptions: LoadOptions): void {
        this.orderStore.setLoading(true);
        this.orderHttpService.getOrders(loadOptions).subscribe({
            next: (result: GridLoadOrderResult) => {
                const newOrderList: Order[] = result.data?.map((order: Order) => {
                    return {
                        ...order,
                        desiredDeliveryDate: new Date(order.desiredDeliveryDate + '.000Z'),
                        orderDate: new Date(order.orderDate + '.000Z'),
                        details: {
                            ...order.details,
                            predictedDeliveryDate: order.details.predictedDeliveryDate
                                ? new Date(order.details.predictedDeliveryDate + '.000Z')
                                : undefined,
                        },
                    };
                });
                this.orderStore.reset();
                this.orderStore.update({
                    orderList: newOrderList,
                    searchQuery: searchQuery,
                    detailOrder: undefined,
                });
                this.orderStore.setLoading(false);
            },
            error: (error: HttpHeaderResponse) => {
                this.toastService.emitError({
                    code: error.status,
                    message: 'ORDER_OVERVIEW.COULD_NOT_LOAD_ORDERS',
                });
                this.orderStore.reset();
                this.orderStore.setLoading(false);
            },
            complete: () => this.orderStore.setLoading(false),
        });
    }

    public fetchMeasurementDocuments(orderId: string): void {
        this.orderStore.setLoading(true);
        this.orderHttpService.getMeasurementDocuments(orderId).subscribe({
            next: (documents: MeasurementDocument[]) => {
                this.orderStore.update({
                    measurementDocuments: documents,
                });
                this.orderStore.setLoading(false);
            },
            error: (error: HttpHeaderResponse) => {
                this.toastService.emitError({
                    code: error.status,
                    message: 'ORDER_DETAILS.COULD_NOT_LOAD_MEASURMENT_DOCUMENTS',
                });
                this.orderStore.reset();
                this.orderStore.setLoading(false);
            },
            complete: () => this.orderStore.setLoading(false),
        });
    }

    public uploadFile(file: File, orderId: string): Observable<HttpResponse<number>> {
        return this.orderHttpService.uploadFile(file, orderId);
    }

    public getFile(orderId: string, fileIdentifier: string): Observable<Blob> {
        return this.orderHttpService.getFile(orderId, fileIdentifier);
    }

    public deleteFile(orderId: string, fileIdentifier: string): Observable<HttpResponse<number>> {
        return this.orderHttpService.deleteFile(orderId, fileIdentifier);
    }

    public updateActualRingThicknesses(orderId: string, ringThicknesses: MeasurementData): void {
        this.orderHttpService.putActualRingThicknesses(orderId, ringThicknesses).subscribe({
            complete: () => {
                this.setDetailOrder(orderId);
                this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
            },
        });
    }

    public updateActualRingThicknessesInDataGrid(orderId: string, ringThicknesses: MeasurementData): Observable<MeasurementData> {
        return this.orderHttpService.putActualRingThicknesses(orderId, ringThicknesses);
    }

    public selectedOrder(selectedOrderId: string): void {
        this.setDetailOrder(selectedOrderId);
    }

    public updateOrderDetails(orderId: string, orderDetails: OrderDetails): void {
        this.orderStore.setLoading(true);
        this.orderHttpService.putOrderDetails(orderId, orderDetails).subscribe({
            next: () => {
                this.setDetailOrder(orderId);
            },
            error: (error: HttpHeaderResponse) => {
                if (error.status === 409)
                    this.toastService.emitError({
                        code: error.status,
                        message: 'ORDER_OVERVIEW.NOT_ALL_RECORDS_HAVE_BEEN_RECORDED',
                    });
                else
                    this.toastService.emitError({
                        code: error.status,
                        message: 'ORDER_OVERVIEW.COULD_NOT_SUBMIT_ORDER_CHANGES',
                    });
                this.orderStore.setLoading(false);
            },
            complete: () => {
                this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
            },
        });
    }

    public getDeliveryNote(orderId: string): void {
        this.orderHttpService.getDeliveryNote(orderId).subscribe((response: Blob) => {
            const url: string = URL.createObjectURL(response);
            window.open(url);
        });
    }

    public sendComplaint(orderId: string, newThicknesses: number[] | null): Observable<ComplaintResponse> {
        return this.orderHttpService.putComplaint(orderId, { newRingThicknessTarget: newThicknesses } as ComplaintRequest);
    }

    public toggleOversize(orderId: string): Observable<Order> {
        return this.orderHttpService.putOversize(orderId);
    }

    public toggleRawMaterialMissing(orderId: string): Observable<Order> {
        return this.orderHttpService.toggleRawMaterialMissing(orderId);
    }

    public sendRingReceived(orderId: string, ringReceived: boolean): Observable<HttpResponse<number>> {
        return this.orderHttpService.patchRingReceived(orderId, ringReceived);
    }

    private setDetailOrder(orderId: string): void {
        this.orderHttpService.getOrder(orderId).subscribe({
            next: (order: Order) => {
                if (!order) {
                    this.orderStore.setError(`Could not find Order by OrderId ${orderId}`);
                    return;
                }
                order.details.predictedDeliveryDate = order.details.predictedDeliveryDate
                    ? new Date(order.details.predictedDeliveryDate + '.000Z')
                    : undefined;
                order.desiredDeliveryDate = new Date(order.desiredDeliveryDate + '.000Z');
                order.orderDate = new Date(order.orderDate + '.000Z');
                this.orderStore.update({
                    detailOrder: order,
                });
                this.orderStore.setLoading(false);
            },
            error: (error: HttpHeaderResponse) => {
                this.toastService.emitError({
                    code: error.status,
                    message: 'ORDER_DETAILS.MISSING_ORDERID',
                });
                this.orderStore.update({
                    detailOrder: {} as Order,
                });
                this.orderStore.setLoading(false);
            },
            complete: () => this.orderStore.setLoading(false),
        });
    }

    public deliveryDatesDryRun(deliveryDate: Date, orderIds: string[]): Observable<OrderDeliveryDateBulkResponse> {
        const orderDeliveryDateBulkRequestDryRun: OrderDeliveryDateBulkRequestDryRun = { deliveryDate: deliveryDate, orderIds: orderIds };

        return this.orderHttpService.putDeliveryDatesDryRun(orderDeliveryDateBulkRequestDryRun).pipe(
            map((response: OrderDeliveryDateBulkResponse) => {
                const result: OrderDeliveryDateBulkResponse = response;

                result.orders = response.orders.map((order: BulkEditOrder) => {
                    return {
                        ...order,
                        orderId: order.orderId,
                        deliveryDate: new Date(order.deliveryDate.toString().substring(0, 19) + '.000Z'),
                        newDeliveryDate: new Date(order.newDeliveryDate.toString().substring(0, 19) + '.000Z')
                    };
                });
                return result;
            }),
            catchError((error: HttpHeaderResponse) => {
                throw error;
            })
        );
    }

    public deliveryDatesCommit( orderIds: string[], orderIdWithDeliveryDate: OrderIdWithDeliveryDate[],deliveryDate: Date): Observable<OrderDeliveryDateBulkResponse> {
        const orderDeliveryDateBulkRequestCommit: OrderDeliveryDateBulkRequestCommit = { orderIds: orderIds, orders: orderIdWithDeliveryDate, deliveryDate: deliveryDate };

        return this.orderHttpService.putDeliveryDatesCommit(orderDeliveryDateBulkRequestCommit).pipe(
            map((response: OrderDeliveryDateBulkResponse) => {
                if (response.state === 2)
                    this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
                response.orders = response.orders.map((order: BulkEditOrder) => {
                    return {
                        ...order,
                        deliveryDate: new Date(order.deliveryDate.toString().substring(0, 19) + '.000Z'),
                        newDeliveryDate: new Date(order.newDeliveryDate.toString().substring(0, 19) + '.000Z')
                    };
                });
                return response;
            }),
            catchError((error: HttpHeaderResponse) => {
                this.toastService.emitError({
                    code: error.status,
                    message: 'ORDER_OVERVIEW.COULD_NOT_SUBMIT_ORDER_CHANGES',
                });

                throw error;
            })
        );
    }
}
