export const ENVIRONMENT = {
    production: false,
    ressourceEndpoint: 'https://localhost:7002/',
    clientId: '58f8cb58-58a8-4676-938d-ff637a4e8bec',
    clientSecret: 'w5l8Q~zD2zVoxw7qI-0wYFJhNsInYVwmXUa7maIg',
    zeissIdHost: 'https://id-ip-stage.zeiss.com/OAuth/OpenidConfiguration',
    tokenEndpoint: 'https://id-ip-stage.zeiss.com/oauth2/v2.0/token?p=B2C_1A_ZeissIdNormalSignIn',
    end_session_endpoint: 'https://id-ip-stage.zeiss.com/oauth2/v2.0/logout?p=B2C_1A_ZeissIdNormalSignIn',
    zeissIdDebug: true,
    subscriptionKey: '20be50cb02c846489b9de9332d4abdde',
    issuer: 'https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/',
    versionNumber: 'v1.0.0',
    appInsightsConnectionString: '',
    appInsightsInstrumentationKey: '',
};
