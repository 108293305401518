<dx-toolbar>
    <dxi-item location="before" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <zui-zeiss-logo size="s32"></zui-zeiss-logo>
        </div>
    </dxi-item>
    <dxi-item location="before" widget="dxButton" locateInMenu="never">
        <div *dxTemplate class="productname-and-version">
            <div class="productname">{{ 'HEADERBAR.PRODUCT_NAME' | translate }}</div>
            <div class="sub-productname">{{ 'HEADERBAR.PRODUCT_SUBNAME' | translate }}</div>
            <div class="version-info">Version: {{versionNumber}}</div>
        </div>
    </dxi-item>
    <dxi-item location="before" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <app-christmas-greetings></app-christmas-greetings>
        </div>
    </dxi-item>
    <dxi-item location="after" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <div class="icon-container" id="info-tooltip">
                <span class="dx-icon-info icon" (click)="openReleaseNotes()"></span>
            </div>
        </div>
    </dxi-item>
    <dxi-item location="after" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <div class="separation-line">|</div>
        </div>
    </dxi-item>
    <dxi-item location="after" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <div class="icon-container" id="user-information-tooltip" (click)="openUserInfo()">
                <span class="dx-icon-user icon"></span>
                <div>{{ user.firstName }} {{ user.lastName }}</div>
            </div>
        </div>
    </dxi-item>
    <dxi-item location="after" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <div class="separation-line">|</div>
        </div>
    </dxi-item>
    <dxi-item location="after" widget="dxButton" locateInMenu="never">
        <div *dxTemplate>
            <div class="icon-container" id="logout-tooltip">
                <span class="dx-icon-panelleft icon" (click)="logout()"></span>
            </div>
        </div>
    </dxi-item>
</dx-toolbar>
<dx-tooltip target="#info-tooltip" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
    <div>
        {{ 'HEADERBAR.TOOLBAR.RELEASENOTES_TOOLTIP' | translate }}
    </div>
</dx-tooltip>
<dx-tooltip target="#user-information-tooltip" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
    <div>
        {{ 'HEADERBAR.TOOLBAR.USER_INFORMATION_TOOLTIP' | translate }}
    </div>
</dx-tooltip>
<dx-tooltip target="#logout-tooltip" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
    <div>
        {{ 'HEADERBAR.TOOLBAR.LOGOUT_TOOLTIP' | translate }}
    </div>
</dx-tooltip>

<dx-popup
    id="user-info"
    [(visible)]="isUserInfoPopUpVisible"
    [hideOnOutsideClick]="true"
    [showTitle]="true"
    [dragEnabled]="false"
    [title]="'HEADERBAR.TOOLBAR.USER_INFORMATION_TOOLTIP' | translate"
    [height]="350"
    [width]="800"
    [resizeEnabled]="false"
    (onHiding)="closeUserInfo()"
>
    <app-user-information></app-user-information>
</dx-popup>

<dx-popup
    id="user-info"
    [(visible)]="isReleaseNotesPopUpVisible"
    [hideOnOutsideClick]="true"
    [showTitle]="true"
    [dragEnabled]="false"
    [title]="'HEADERBAR.TOOLBAR.RELEASENOTES_TOOLTIP' | translate"
    [height]="500"
    [width]="800"
    [resizeEnabled]="false"
    (onHiding)="closeReleaseNotes()"
>
    <app-release-notes></app-release-notes>
</dx-popup>