import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public intercept(req: HttpRequest<any>, next: HttpHandler) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const authReq: HttpRequest<any> = req.clone({
            headers: req.headers
                .set('Authorization', `Bearer ${this.auth.idToken}`)
                .set('Access-Control-Allow-Origin', '*')
                .set('Pdx-Subscription-Key', ENVIRONMENT.subscriptionKey),
        });
        return next.handle(authReq);
    }
}
