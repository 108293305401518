import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComplaintResponse, Order, ProductCategory, UnitOfMeasure } from '../../models/order.type';
import { AuthQuery } from 'src/app/main/store/auth/auth.query';
import { User } from '../../models/user.type';
import { OrderService } from 'src/app/main/services/order/order.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { ToastService } from '../../services/toast/toast.service';

@Component({
    selector: 'app-complaint-dialog',
    templateUrl: './complaint-dialog.component.html',
    styleUrls: ['./complaint-dialog.component.scss'],
})
export class ComplaintDialogComponent implements OnChanges {
    @Input() complainedOrder!: Order;

    @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();

    public newTargetRingThicknesses: string[] = [];
    public canSendComplaint: boolean = false;
    public user!: User;
    public headerText!: string;
    public showInputs: boolean = false;
    public buttonClicked: boolean = false;

    constructor(
        readonly translateService: TranslateService,
        readonly authQuery: AuthQuery,
        readonly orderService: OrderService,
        private toastService: ToastService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['complainedOrder']) this.initView();
    }

    private initView(): void {
        this.fetchPermissions();
        if (this.complainedOrder?.productCategory === 2 || this.complainedOrder?.productCategory === 3) {
            this.showInputs = true;
            this.headerText = this.translateService.instant('COMPLAINT_DIALOG.HEADER_TEXT.WITH_INPUTS');
        } else {
            this.showInputs = false;
            this.headerText = this.translateService.instant('COMPLAINT_DIALOG.HEADER_TEXT.WITHOUT_INPUTS');
        }
        this.complainedOrder?.recipeData?.ringThicknessTarget.forEach((ringDepth: number, index: number) => {
            this.newTargetRingThicknesses[index] = ringDepth + '';
        });
    }

    public isDelta(order: Order) : boolean {
        return order.productCategory == ProductCategory.Optikring || order.productCategory == ProductCategory.Erdteil;
    }
    
    public sendComplaint(): void {
        if (this.canSendComplaint) {
            this.buttonClicked = true;
            let convertThicknesses: number[] | null = [];
            if(this.complainedOrder?.productCategory === 2 || this.complainedOrder?.productCategory === 3) {
                this.newTargetRingThicknesses.forEach((thickness: string, index: number) => {
                    if (thickness !== '' && convertThicknesses) convertThicknesses[index] = parseFloat(thickness);
                });
            } else {
                convertThicknesses = null;
            }
            this.orderService.sendComplaint(this.complainedOrder.id, convertThicknesses).subscribe({
                next: (result: ComplaintResponse) => {
                    this.closeDialog.emit(result.id);
                },
                error: (error: HttpHeaderResponse) => {
                    this.toastService.emitError({
                        code: error.status,
                        message: 'ORDER_OVERVIEW.COULD_NOT_CREATE_COMPLAINT',
                    });
                    this.closeDialog.emit('');
                },
                complete: () => {
                    this.toastService.emitSuccess({
                        message: 'ORDER_OVERVIEW.COMPLAINT',
                    });
                    this.closeDialog.emit('');
                    this.buttonClicked = false;
                },
            });
        } else {
            this.toastService.emitError({
                message: 'ORDER_DETAILS.MISSING_PERMISSIONS_SEND_COMPLAINT',
            });
            this.closeDialog.emit('');
        }
    }

    public closeComplaintDialog(): void {
        this.closeDialog.emit('');
    }

    private fetchPermissions(): void {
        this.authQuery.user$.subscribe({
            next: (user: User) => {
                this.user = user !== undefined ? user : ({} as User);
                this.canSendComplaint = this.user?.permissions?.find((permission: string) => permission === 'CAN_RETRIGGER_AN_ORDER_IMPORT')
                    ? true
                    : false;
            },
        });
    }

    public trackByIndex(index: number): number {
        return index;
    }

    getUnitOfMeasure(complainedOrder: Order) {
        if(this.isDelta(complainedOrder)){
            return UnitOfMeasure.μm;
        }
        
        return complainedOrder.recipeData.ringThicknessTargetUnit;
    }
}
