import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { Observable } from 'rxjs';
import {
    ComplaintRequest,
    ComplaintResponse,
    GridLoadOrderResult,
    MeasurementData,
    MeasurementDocument,
    Order,
    OrderDeliveryDateBulkRequestCommit,
    OrderDeliveryDateBulkRequestDryRun,
    OrderDeliveryDateBulkResponse,
    OrderDetails
} from 'src/app/common/models/order.type';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class OrderHttpService {
    constructor(private http: HttpClient) {}

    /**
     * getOrders
     *
     * @param { LoadOptions } loadOptions filter object to pre-select orders
     * @returns {Observable<Order[]>} a observable that Emits a Array of all Orders
     */
    public getOrders(loadOptions: LoadOptions): Observable<GridLoadOrderResult> {
        let params: HttpParams = new HttpParams();
        ['parentIds', 'filter', 'sort'].forEach((i: string) => {
            if (
                i in loadOptions &&
                loadOptions[i as keyof LoadOptions] !== undefined &&
                loadOptions[i as keyof LoadOptions] !== null &&
                loadOptions[i as keyof LoadOptions] !== ''
            ) {
                params = params.set(i, JSON.stringify(loadOptions[i as keyof LoadOptions]));
            }
        });
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order`;
        return this.http.get<GridLoadOrderResult>(url, { params: params });
    }

    /**
     * Gets a specific order
     *
     * @param { string } orderId of the order which should be loaded
     * @returns {Observable<Order>} the fetched order
     */
    public getOrder(orderId: string): Observable<Order> {
        return this.http.get<Order>(`${ENVIRONMENT.ressourceEndpoint}order/${orderId}`);
    }

    /**
     * Get measurement documents from an order
     *
     * @param { string } orderId from the order from which the documents should be loaded
     * @returns {Observable<MeasurementDocument[]>} a observable that Emits a Array of all MeasurementDocuments for the specified orderId
     */
    public getMeasurementDocuments(orderId: string): Observable<MeasurementDocument[]> {
        return this.http.get<MeasurementDocument[]>(`${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement`);
    }

    /**
     * Upload measurement document for specified order
     *
     * @param { File } file from document which should be uploaded
     * @param { string } orderId from the order from which the document should be uploaded
     * @returns { Observable<HttpResponse<number>> } a observable that Emits the status code
     */
    public uploadFile(file: File, orderId: string): Observable<HttpResponse<number>> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement`;
        return this.http.post<HttpResponse<number>>(url, formData);
    }

    /**
     * Get blob of the desired file
     *
     * @param { string } orderId from the order
     * @param { string } fileIdentifier which identifies the file
     * @returns { Observable<Blob> } blob of the desired file
     */
    public getFile(orderId: string, fileIdentifier: string): Observable<Blob> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement/${fileIdentifier}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    /**
     * Upload measurement document for specified order
     *
     * @param { string } orderId from the order from which the document should be deleted
     * @param { string } fileIdentifier identifies the file which should be deleted
     * @returns { Observable<HttpResponse<number>> } a observable that Emits the status code
     */
    public deleteFile(orderId: string, fileIdentifier: string): Observable<HttpResponse<number>> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement/${fileIdentifier}`;
        return this.http.delete<HttpResponse<number>>(url);
    }

    /**
     *  Updates the details of a specific order
     *
     * @param { string } orderId of the order whose details are updated
     * @param { OrderDetails } orderDetails that should be updated
     * @returns { Observable<HttpResponse<number>> } an observable that Emits the status code
     */
    public putOrderDetails(orderId: string, orderDetails: OrderDetails): Observable<HttpResponse<number>> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/details`;
        return this.http.put<HttpResponse<number>>(url, orderDetails);
    }
    /**
     *  TODO: Put to update selected Order
     *
     * @param { string } orderId from the order
     * @param { MeasurementData } ringThicknesses new ringThicknesses that should be updated
     * @returns {Observable<Order>} a observable that Emits a Array of all Orders
     */
    public putActualRingThicknesses(orderId: string, ringThicknesses: MeasurementData): Observable<MeasurementData> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurements`;
        return this.http.put<MeasurementData>(url, ringThicknesses);
    }

    public getDeliveryNote(orderId: string): Observable<Blob> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/delivery-note`;
        return this.http.get(url, { responseType: 'blob' });
    }

    public putComplaint(orderId: string, complaintRequest: ComplaintRequest): Observable<ComplaintResponse> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/complain`;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const body: ComplaintRequest | null = complaintRequest.newRingThicknessTarget ? complaintRequest : null;

        return this.http.put<ComplaintResponse>(url, body, { headers });
    }

    public putOversize(orderId: string): Observable<Order> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/oversize`;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.put<Order>(url, null, { headers });
    }

    public toggleRawMaterialMissing(orderId: string): Observable<Order> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/raw-material-missing`;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.put<Order>(url, null, { headers });
    }

    public patchRingReceived(orderId: string, ringReceived: boolean): Observable<HttpResponse<number>> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/received/${ringReceived}`;
        return this.http.patch<HttpResponse<number>>(url, null);
    }

    public putDeliveryDatesDryRun(orderDeliveryDateBulkRequestDryRun: OrderDeliveryDateBulkRequestDryRun): Observable<OrderDeliveryDateBulkResponse> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/bulk/delivery-date/dry-run`;
        return this.http.put<OrderDeliveryDateBulkResponse>(url, orderDeliveryDateBulkRequestDryRun);
    }

    public putDeliveryDatesCommit(orderDeliveryDateBulkRequestCommit: OrderDeliveryDateBulkRequestCommit): Observable<OrderDeliveryDateBulkResponse> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/bulk/delivery-date/commit`;
        return this.http.put<OrderDeliveryDateBulkResponse>(url, orderDeliveryDateBulkRequestCommit);
    }
}
