<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" *ngIf="this.canChangeDocuments">
    <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
    >
        <div class="drop-off-label" (click)="openFileSelector()">
            {{ 'ORDER_DETAIL.MEASUREMENTS_CARD.DROPOFF' | translate }}
        </div>
    </ng-template>
</ngx-file-drop>
<div *ngIf="measurementDocuments && measurementDocuments.length > 0" class="file-list">
    <dx-list
        [dataSource]="measurementDocuments"
        [height]="150"
    >
    <div *dxTemplate="let item of 'item'" class="list-item">
        <div class="list-item-container">
                <div *ngIf="this.canChangeDocuments" class="dx-icon-trash delete-icon" (click)="deleteFile(item)"></div>
                <div class="file-container" (click)="openFile(item)">
                    <div class="dx-icon-file file-icon"></div>
                    <div class="file-name">{{ item.fileName }}</div>
                </div>
        </div>
      </div>
  </dx-list>
</div>

<div class="loading-message-container" *ngIf="!measurementDocuments">
    <dx-load-indicator height="20" width="20"></dx-load-indicator>
    <p class="loading-message">{{ 'ORDER_DETAIL.MEASUREMENTS_CARD.LOADING' | translate }}</p>
</div>
