import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        readonly authService: AuthService,
        readonly routerService: Router
    ) {}

    /**
     * Checks if Valid Id Token is stored in authStorage
     * if no Valid Id Token is stored in authStorage, it trys to refresh the Token
     * if Refresh faild it will navigate to login
     *
     * @returns {boolean | Observable<boolean>} true when valid token is present. Returns Observable when no Valid Token is present.
     */
    public canActivate(): boolean | Observable<boolean> {
        if (this.authService.hasValidToken()) {
            return true;
        }

        return this.refreshTokenOrNavigateToLogin();
    }

    private refreshTokenOrNavigateToLogin(): Observable<boolean> {
        return this.authService.tryRefreshToken().pipe(
            tap((isTokenRefreshed: boolean) => {
                if (!isTokenRefreshed) {
                    this.routerService.navigate(['/login']);
                }
            })
        );
    }
}
