import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { OrderSearchResultItem } from '../order-data-grid/order-data-grid.component';
import { OrderService } from 'src/app/main/services/order/order.service';
import { BulkEditOrder, OrderDeliveryDateBulkResponse, OrderIdWithDeliveryDate } from 'src/app/common/models/order.type';
import { Subject, finalize, takeUntil } from 'rxjs';

@Component({
    selector: 'app-order-bulk-edit',
    templateUrl: './order-bulk-edit.component.html',
    styleUrls: ['./order-bulk-edit.component.scss'],
})
export class OrderBulkEditComponent implements OnChanges {
    public selectedDate?: Date;
    public loadIndicatorVisible: boolean = false;
    public orderIdWithDeliveryDate!: OrderIdWithDeliveryDate[];
    public datesChecked: boolean = false;
    public showInformationText: boolean = false;

    public orders: BulkEditOrder[] = [];
    private initialRequestedOrders: OrderSearchResultItem[] = [];
    private destroyed$ = new Subject<void>();

    @Input() set setOrders(value: OrderSearchResultItem[]) {
        this.selectedDate = !value[0] || !value[0].desiredDeliveryDate ? new Date() : value[0].desiredDeliveryDate;
        this.orders = value
            .map((order: OrderSearchResultItem) => {
                return {
                    orderId: order.id,
                    materialNumber: order.materialNumber,
                    productCategory: order.productCategory,
                    ordererDepartment: order.ordererDepartment,
                    sapProductionOrderNumber: order.sapProductionOrderNumber,
                    recipePriority: order.recipePriority,
                    ringDiameter: order.recipeData.ringDiameter,
                    ringDiameterUnit: order.recipeData.ringDiameterUnit,
                    set: order.set,
                    deliveryDate: order.desiredDeliveryDate,
                } as BulkEditOrder;
            })
            .sort((o1: BulkEditOrder, o2: BulkEditOrder): number => {
                if (o1.deliveryDate < o2.deliveryDate) {
                    return -1;
                }

                if (o1.deliveryDate > o2.deliveryDate) {
                    return 1;
                }

                if (o1.sapProductionOrderNumber === o2.sapProductionOrderNumber && o1.set === o2.set && o1.recipePriority < o2.recipePriority) {
                    return -1;
                }

                return 1;
            });
    }

    @Output() closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(readonly orderService: OrderService) {}

    ngOnChanges(changes: SimpleChanges) {
        const ordersValue: SimpleChange = changes['setOrders'];
        this.initialRequestedOrders = ordersValue.currentValue;
        this.orderIdWithDeliveryDate = [];
        this.showInformationText = false;
    }

    public saveClicked(): void {
        if (this.selectedDate) {
            this.destroyed$.next();
            this.loadIndicatorVisible = true;
            const orderIds: string[] = this.initialRequestedOrders.map((order: OrderSearchResultItem) => order.id);
            this.orderService
                .deliveryDatesCommit(orderIds, this.orderIdWithDeliveryDate, this.selectedDate)
                .pipe(
                    takeUntil(this.destroyed$),
                    finalize(() => {
                        this.loadIndicatorVisible = false;
                    })
                )
                .subscribe((orderDeliveryDateBulkResponse: OrderDeliveryDateBulkResponse) => {
                    this.orderIdWithDeliveryDate = orderDeliveryDateBulkResponse.orders.map((order: BulkEditOrder) => {
                        return {
                            orderId: order.orderId,
                            deliveryDate: order.newDeliveryDate,
                        };
                    });
                    this.orders = orderDeliveryDateBulkResponse.orders;
                    if (orderDeliveryDateBulkResponse.state !== 2) {
                        this.showInformationText = true;
                    } else {
                        this.closePopup.emit(true);
                        this.showInformationText = false;
                    }
                });
        }
    }

    public selectedDateChanged(): void {
        this.datesChecked = false;
    }

    public checkClicked(): void {
        if (this.selectedDate) {
            this.destroyed$.next();
            this.loadIndicatorVisible = true;
            const orderIds: string[] = this.initialRequestedOrders.map((order: OrderSearchResultItem) => order.id);
            this.orderService
                .deliveryDatesDryRun(this.selectedDate, orderIds)
                .pipe(
                    takeUntil(this.destroyed$),
                    finalize(() => {
                        this.loadIndicatorVisible = false;
                        this.datesChecked = true;
                        this.showInformationText = false;
                    })
                )
                .subscribe((orderDeliveryDateBulkResponse: OrderDeliveryDateBulkResponse) => {
                    this.orders = orderDeliveryDateBulkResponse.orders;
                    this.orderIdWithDeliveryDate = orderDeliveryDateBulkResponse.orders.map((order: BulkEditOrder) => {
                        return {
                            orderId: order.orderId,
                            deliveryDate: order.newDeliveryDate,
                        };
                    });
                });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onRowPrepared(e: any): void {
        if (e.rowType === 'data' && !this.wasOrderInitialRequested(e.data.orderId)) {
            e.rowElement.find('td').css('background-color', 'rgba(20, 48, 72, 0.30)');
        }
    }

    private wasOrderInitialRequested(orderId: string): boolean {
        return this.initialRequestedOrders.find((order: OrderSearchResultItem) => order.id === orderId) ? true : false;
    }
}
