<div class="order-bulk-edit">
    <div class="dx-fieldset">
        <div class="dx-field">
            <div class="dx-field-label hint-icon">
                {{ 'ORDER_BULK_EDIT.POPUP.DATE_LABEL' | translate }}
                <i id="hint" class="dx-icon-help"></i>
            </div>

            <div class="dx-field-value">
                <div class="date-box-container">
                    <dx-date-box
                        class="date-box"
                        [(ngModel)]="selectedDate"
                        (onValueChanged)="selectedDateChanged()"
                        type="datetime"
                        stylingMode="underlined"
                        placeholder="DD.MM.YYYY HH:MM"
                        displayFormat="dd.MM.yyyy HH:mm"
                    >
                    </dx-date-box>

                    <dx-button
                        class="button"
                        [type]="datesChecked ? 'normal' : 'default'"
                        [disabled]="loadIndicatorVisible || !selectedDate"
                        (onClick)="checkClicked()"
                    >
                        <span class="dx-button-text">{{ 'ORDER_BULK_EDIT.POPUP.ACTIONS.CHECK' | translate }}</span>
                    </dx-button>
                </div>
            </div>
        </div>
        <dx-popover target="#hint" showEvent="dxhoverstart" hideEvent="dxhoverend">
            <div *dxTemplate="let data of 'content'">
                <p style="width: 300px">
                    {{ 'ORDER_BULK_EDIT.POPUP.TOOLTIP' | translate }}
                </p>
            </div>
        </dx-popover>
    </div>

    <dx-data-grid
        [dataSource]="orders"
        keyExpr="orderId"
        [height]="300"
        width="100%"
        [rowAlternationEnabled]="true"
        (onRowPrepared)="onRowPrepared($event)"
    >
        <dxo-editing [allowDeleting]="true" [useIcons]="true">
            <dxo-texts deleteRow="{{ 'ORDER_BULK_EDIT.POPUP.ACTIONS.DELETE' | translate }}" confirmDeleteMessage=""> </dxo-texts>
        </dxo-editing>

        <dxo-sorting mode="none" />
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxi-column
            dataField="materialNumber"
            [width]="150"
            caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.MATERIAL' | translate }}"
            dataType="string"
        />
        <dxi-column
            dataField="productCategory"
            caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.PRODUCTCATEGORY' | translate }}"
            cellTemplate="productCategory"
            [width]="130"
        ></dxi-column>
        <dxi-column dataField="recipePriority" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RECIPEPRIORITY' | translate }}" [width]="120" />

        <dxi-column caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RINGDIAMETER' | translate }}" cellTemplate="ringDiameter" [width]="120" />

        <dxi-column dataField="set" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.SET' | translate }}" dataType="string" [width]="80" />

        <dxi-column
            name="deliveryDate"
            dataField="deliveryDate"
            caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.DESIRED_DELIVERY_DATE' | translate }}"
            cssClass="cell-highlighted"
            [width]="130"
            cellTemplate="deliveryDate"
        />

        <dxi-column
            name="newDeliveryDate"
            dataField="newDeliveryDate"
            caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.NEW_DESIRED_DELIVERY_DATE' | translate }}"
            cssClass="cell-highlighted-red"
            [width]="150"
            cellTemplate="newDeliveryDate"
        />

        <ng-container *dxTemplate="let date of 'deliveryDate'">
            {{ date.value | date : 'dd.MM.yyyy HH:mm' }}
        </ng-container>

        <ng-container *dxTemplate="let newDeliveryDate of 'newDeliveryDate'">
            <div *ngIf="newDeliveryDate.value">
                {{ newDeliveryDate.value | date : 'dd.MM.yyyy HH:mm' }}
            </div>
        </ng-container>

        <ng-container *dxTemplate="let productCategory of 'productCategory'">
            {{ productCategory.value | productCategoryTranslation }}
        </ng-container>

        <ng-container *dxTemplate="let row of 'ringDiameter'">
            {{ row.data.ringDiameter }} {{ row.data.ringDiameterUnit | unitOfMeasure }}
        </ng-container>
    </dx-data-grid>

    <div class="bulk-edit-actions">
        <div class="information-message" *ngIf="showInformationText">
            {{ 'ORDER_BULK_EDIT.POPUP.INFORMATIONMESSAGE' | translate }}
        </div>

        <dx-button
            class="button"
            [type]="datesChecked ? 'default' : 'normal'"
            [disabled]="loadIndicatorVisible || !selectedDate || !datesChecked"
            (onClick)="saveClicked()"
        >
            <dx-load-indicator class="button-indicator" [visible]="loadIndicatorVisible"> </dx-load-indicator>
            <span class="dx-button-text">{{ 'ORDER_BULK_EDIT.POPUP.ACTIONS.SAVE' | translate }}</span>
        </dx-button>

        <dx-button (onClick)="closePopup.emit(false)"> {{ 'ORDER_BULK_EDIT.POPUP.ACTIONS.CANCEL' | translate }}</dx-button>
    </div>
</div>
