import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { ToastStore } from 'src/app/main/store/toast/toast.store';
import { ToastContent, ToastState } from '../../models/toastState.type';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private readonly errorTranslationPrefix = 'ERROR';
    private readonly successTranslationPrefix = 'SUCCESS';
    private readonly defaultErrorHeader = `${this.errorTranslationPrefix}.DEFAULT_HEADER`;
    private readonly defaultSuccessHeader = `${this.successTranslationPrefix}.DEFAULT_HEADER`;

    constructor(private toastStore: ToastStore) {}

    public emitError(error: ToastContent): void {
        if (!error.headerText) {
            error.headerText = this.defaultErrorHeader;
        }
        if (error.message) {
            error.message = this.errorTranslationPrefix + '.' + error.message;
        }
        this.toastStore.update((state: ToastState) => {
            return {
                ...state,
                errorToast: {
                    content: error,
                    active: true,
                },
            };
        });

        this.setHideErrorToast(8000);
    }

    public emitSuccess(success: ToastContent): void {
        if (!success.headerText) {
            success.headerText = this.defaultSuccessHeader;
        }
        if (success.message) {
            success.message = this.successTranslationPrefix + '.' + success.message;
        }
        this.toastStore.update((state: ToastState) => {
            return {
                ...state,
                successToast: {
                    content: success,
                    active: true,
                },
            };
        });

        this.setHideSuccessToast(8000);
    }

    public setHideErrorToast(hideToastInMs: number): void {
        timer(hideToastInMs).subscribe(() => {
            this.toastStore.update((state: ToastState) => {
                return {
                    ...state,
                    errorToast: {
                        ...state.errorToast,
                        active: false,
                    },
                };
            });
        });
    }

    public setHideSuccessToast(hideToastInMs: number): void {
        timer(hideToastInMs).subscribe(() => {
            this.toastStore.update((state: ToastState) => {
                return {
                    ...state,
                    successToast: {
                        ...state.successToast,
                        active: false,
                    },
                };
            });
        });
    }
}
