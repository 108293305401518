<div class="dialog">
    <p class="header-text">{{ headerText }}</p>
    <table class="ring-thickness" *ngIf="showInputs">
        <tr>
            <th class="text-column">#</th>
            <th>{{ 'ORDER_DETAIL.RECIPE_CARD.REQUIRED_RING_DEPTH' | translate }}</th>
        </tr>
        <tr *ngFor="let _ of newTargetRingThicknesses; let i = index;  trackBy: trackByIndex">
            <td class="text-column">{{ 'ORDER_DETAIL.RECIPE_CARD.VALUE' | translate }} {{ i + 1 }}</td>
            <td class="input-field">
                <span *ngIf="isDelta(complainedOrder)">&#916;</span>
                <dx-number-box
                    name="number"
                    [step]="0.1"
                    [showSpinButtons]="true"
                    [(ngModel)]="newTargetRingThicknesses[i]"
                    class="number-box"
                    valueChangeEvent="keyup"
                ></dx-number-box>
                {{ getUnitOfMeasure(complainedOrder) | unitOfMeasure }}
            </td>
        </tr>
    </table>
    <div class="buttons">
        <dx-button (click)="sendComplaint()" class="submit-button" [disabled]="buttonClicked">
            {{ 'COMPLAINT_DIALOG.ACCEPT_BUTTON' | translate }}
        </dx-button>
        <dx-button (click)="closeComplaintDialog()">
            {{ 'COMPLAINT_DIALOG.CANCEL_BUTTON' | translate }}
        </dx-button>
    </div>
</div>