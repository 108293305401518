import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-christmas-greetings',
    templateUrl: './christmas-greetings.component.html',
    styleUrls: ['./christmas-greetings.component.scss'],
})
export class ChristmasGreetingsComponent implements OnInit {

    public today: Date = new Date();
    public isChristmasPeriod: boolean = false;

    constructor(readonly translateService: TranslateService) {}

    ngOnInit(): void {
        const actualDate = this.today.getDate();
        // Add one because this.today.getMonth() returns the month (0 to 11) of a date. January =0, February = 1, ...
        const actualMonth = this.today.getMonth()+1;
        this.isChristmasPeriod = (actualMonth === 12 && (actualDate >= 1 && actualDate <= 26));
    }
}
