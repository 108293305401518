import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable()
export class LoggingService {

    private appInsights: ApplicationInsights | undefined;

    constructor() {
        if(ENVIRONMENT.appInsightsConnectionString.length > 0) {
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: ENVIRONMENT.appInsightsConnectionString,
                    enableAutoRouteTracking: true,
                    enableRequestHeaderTracking: true,
                },
            });
            this.appInsights.loadAppInsights();
        } else {
            this.appInsights = undefined;
        }
    }

    public logPageView(name?: string, url?: string): void {
        this.appInsights?.trackPageView({
            name: name,
            uri: url,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackEvent({ name: name }, properties);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights?.trackMetric({ name: name, average: average }, properties);
    }

    public logException(exception: Error, severityLevel?: number) {
        this.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackTrace({ message: message }, properties);
    }
}
