import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { Observable, skipWhile, switchMap } from 'rxjs';
import { Order, OrderSearch } from 'src/app/common/models/order.type';
import { OrderService } from 'src/app/main/services/order/order.service';
import { OrderQuery } from 'src/app/main/store/order/order.query';

@Injectable({
    providedIn: 'root',
})
export class OrdersSearchService {
    constructor(
        readonly orderService: OrderService,
        readonly orderQuery: OrderQuery
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public fetchSearchResults(
        searchRequest: OrderSearch,
        loadOptions: LoadOptions
    ): Observable<Order[]> {
        this.orderService.fetchOrderResults(searchRequest, loadOptions);
        return this.orderQuery.selectLoading().pipe(
            skipWhile((isLoading: boolean) => isLoading),
            switchMap(() => this.orderQuery.searchResultQuery$)
        );
    }
}
