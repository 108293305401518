import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { STATE_TRANSLATION_CONFIG } from '../../configuration/state-translation';
import { OrderStates } from '../../models/order.type';

@Injectable({
    providedIn: 'root',
})
export class StateTranslationService {
    constructor(readonly translateService: TranslateService) {}

    public getValueTranslation(state: OrderStates | undefined | string): string {
        let translationKey: string;
        if (state != null) {
            translationKey = '';
            if (state === 1 || state === 'InProduction') translationKey = STATE_TRANSLATION_CONFIG.translationPrefix + STATE_TRANSLATION_CONFIG.translationKeyMap['inProduction'];
            if (state === 2 || state === 'Shipped') translationKey = STATE_TRANSLATION_CONFIG.translationPrefix + STATE_TRANSLATION_CONFIG.translationKeyMap['shipped'];
            if (state === 3 || state === 'Ordered') translationKey = STATE_TRANSLATION_CONFIG.translationPrefix + STATE_TRANSLATION_CONFIG.translationKeyMap['ordered'];
            if (state === 4 || state === 'Rejected') translationKey = STATE_TRANSLATION_CONFIG.translationPrefix + STATE_TRANSLATION_CONFIG.translationKeyMap['rejected'];
        } else {
            translationKey = STATE_TRANSLATION_CONFIG.translationPrefix + STATE_TRANSLATION_CONFIG.translationKeyMap['notDefined'];
        }
        return this.translateService.instant(translationKey);
    }
}
