import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
    ToastNotification,
    ToastState,
} from 'src/app/common/models/toastState.type';
import { ToastStore } from './toast.store';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastQuery extends Query<ToastState> {
    public errorActiveState$: Observable<boolean> = this.select(
        'errorToast'
    ).pipe(map((errorToast: ToastNotification) => errorToast.active));

    public successActiveState$: Observable<boolean> = this.select(
        'successToast'
    ).pipe(map((successToast: ToastNotification) => successToast.active));

    public errorToast$: Observable<ToastNotification> =
        this.select('errorToast');
    public successToast$: Observable<ToastNotification> =
        this.select('successToast');

    constructor(protected override store: ToastStore) {
        super(store);
    }
}
