/* eslint-disable @typescript-eslint/typedef */
export const STATE_TRANSLATION_CONFIG = {
    translationKeyMap: {
        ordered: 'ORDERED',
        inProduction: 'IN_PRODUCTION',
        shipped: 'SHIPPED',
        rejected: 'REJECTED',
        notDefined: 'NOT_DEFINED'
    },
    translationPrefix: 'STATE_DROPDOWN.VALUES.'
};
