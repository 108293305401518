import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { Order, OrderDetails, OrderStateMapping, OrderStates } from 'src/app/common/models/order.type';
import { OrderService } from '../../../../services/order/order.service';
import { User } from 'src/app/common/models/user.type';
import { AuthQuery } from 'src/app/main/store/auth/auth.query';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UtilService } from 'src/app/common/services/utils/utils.service';
import { StateTranslationService } from 'src/app/common/services/state-translation-service/state-translation.service';

@Component({
    selector: 'app-order-context-data-form',
    templateUrl: './order-context-data-form.component.html',
    styleUrls: ['./order-context-data-form.component.scss'],
})
export class OrderContextDataFormComponent implements OnChanges {
    @Input() public order!: Order;
    @Input() set orderChangeIsPending(value: boolean) {
        this.orderChangeProcessStateSubject.next(value);
    }
    @Output() setNewOrderId: EventEmitter<string> = new EventEmitter<string>();

    public orderChangeForm!: FormGroup;
    public changeReasonForm!: FormGroup;
    public submitButtonState$!: Observable<boolean>;
    public resetButtonState$!: Observable<boolean>;
    public orderChangeProcessState$!: Observable<boolean>;

    private unsubmittedFormChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private orderChangeProcessStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public user!: User;
    public isAdmin: boolean = false;
    public canChangeState: boolean = false;
    public canChangePredictedDeliveryDate: boolean = false;
    public canGenerateDeliveryNote: boolean = false;
    public canSendComplaint: boolean = false;
    public canChangeStateToRejected: boolean = false;
    public disableStateChange: boolean = false;
    public userChangesDate: boolean = false;
    public userChangesTime: boolean = false;
    public showChangeReasonDialog: boolean = false;
    public isComplained: boolean = false;
    public stateSelectOptions!: OrderStateMapping[];
    public orderIsChanging: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        readonly orderService: OrderService,
        readonly authQuery: AuthQuery,
        private errorToastService: ToastService,
        readonly router: Router,
        readonly datePipe: DatePipe,
        readonly utilService: UtilService,
        readonly stateTranslationService: StateTranslationService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['order']) this.initView();
    }

    private initView(): void {
        this.fetchPermissions();
        this.orderChangeForm = this.getForm();
        this.changeReasonForm = this.getChangeReasonForm();
        this.initStateSelectOptions();
        this.orderChangeForm.controls['state'].setValue(this.order.details.state);
        this.orderChangeProcessState$ = this.orderChangeProcessStateSubject.asObservable();
        this.setupButtonHandling();
        this.disableStateChange =
            (this.order.details.state?.toString() === 'Shipped' || this.order.details.state?.toString() === 'Rejected') && !this.isAdmin;
        if (!this.canChangeState || this.disableStateChange) this.orderChangeForm.get('state')?.disable();
        if (!this.canChangePredictedDeliveryDate) {
            this.orderChangeForm.get('predictedDeliveryDate')?.disable();
            this.orderChangeForm.get('predictedDeliveryTime')?.disable();
        }
        this.orderChangeForm.controls['predictedDeliveryDate'].valueChanges.subscribe((selectedValue: Date) => {
            if (this.formatDate(selectedValue) !== this.formatDate(this.order.details.predictedDeliveryDate!)) {
                this.userChangesDate = true;
            } else {
                this.userChangesDate = false;
            }
        });
        this.orderChangeForm.controls['predictedDeliveryTime'].valueChanges.subscribe((selectedValue: Date) => {
            if (this.formatTime(selectedValue) !== this.formatTime(this.order.details.predictedDeliveryDate!)) {
                this.userChangesTime = true;
            } else {
                this.userChangesTime = false;
            }
        });
        this.initFormSubscription();
    }

    private initFormSubscription(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.orderChangeForm.valueChanges.subscribe((value: any) => {
            if (
                this.order.details.predictedDeliveryDate !== undefined &&
                this.order.details.predictedDeliveryDate !== null &&
                this.formatDate(value.predictedDeliveryDate) === this.formatDate(this.order.details.predictedDeliveryDate!) &&
                this.formatTime(value.predictedDeliveryTime) === this.formatTime(this.order.details.predictedDeliveryDate!) &&
                value.state[0]?.toLowerCase() === this.order.details.state?.toString().toLowerCase()
            ) {
                this.unsubmittedFormChangeSubject.next(false && this.orderChangeForm.valid);
            } else {
                this.unsubmittedFormChangeSubject.next(true && this.orderChangeForm.valid);
            }
        });
    }

    private initStateSelectOptions(): void {
        this.stateSelectOptions = [
            { value: 'InProduction', displayName: this.stateTranslationService.getValueTranslation('InProduction') },
            { value: 'Shipped', displayName: this.stateTranslationService.getValueTranslation('Shipped') },
            { value: 'Ordered', displayName: this.stateTranslationService.getValueTranslation('Ordered') },
            { value: 'Rejected', displayName: this.stateTranslationService.getValueTranslation('Rejected'), disabled: !this.canChangeStateToRejected }
        ] as OrderStateMapping[];
    }

    public handleCloseEvent(eventData: string) {
        this.isComplained = false;
        if (eventData !== '') this.goToLinkedOrder(eventData);
    }

    public submitOrderChange(): void {
        if (this.userChangesDate || this.userChangesTime) {
            this.showChangeReasonDialog = true;
            this.unsubmittedFormChangeSubject.next(false);
        } else {
            this.showChangeReasonDialog = false;
            this.sendChange();
        }
    }

    public sendChange(): void {
        if (this.canChangeState || this.canChangePredictedDeliveryDate) {
            this.orderChangeIsPending = true;
            const state: OrderStates = (this.canChangeState && !this.isStateShippedOrRejectedAndNoAdmin()) ? parseInt(OrderStates[this.orderChangeForm.value.state]) : this.order.details.state!;
            const predictedDeliveryDate: Date | undefined = this.canChangePredictedDeliveryDate
                ? this.getDateTime()
                : this.order.details.predictedDeliveryDate!;
            const changeReason: string = this.changeReasonForm.value.changeReason;
            this.unsubmittedFormChangeSubject.next(false);
            const orderDetails: OrderDetails = {
                state: OrderStates[state].toString() !== this.order.details.state?.toString() ? state : undefined,
                predictedDeliveryDate:
                    this.utilService.getTimestampStringWithMinutesPrecision(predictedDeliveryDate) !==
                        this.utilService.getTimestampStringWithMinutesPrecision(this.order.details.predictedDeliveryDate)
                        ? predictedDeliveryDate
                        : undefined,
                changeReason: changeReason.length > 0 ? changeReason : undefined,
            };
            this.orderService.updateOrderDetails(this.order.id, orderDetails);
            this.order = {
                ...this.order,
                details: {
                    ...this.order.details,
                    state: state,
                    predictedDeliveryDate: predictedDeliveryDate,
                },
            };
            this.showChangeReasonDialog = false;
            this.userChangesDate = false;
            this.userChangesTime = false;
            this.changeReasonForm.setValue({
                changeReason: '',
            });
        } else {
            this.errorToastService.emitError({
                message: 'ORDER_DETAILS.MISSING_PERMISSIONS_EDIT_ORDER',
            });
        }
        this.orderChangeIsPending = false;
    }

    public resetForm(): void {
        this.unsubmittedFormChangeSubject.next(false);
        this.orderChangeForm.setValue({
            state: [this.order.details.state],
            predictedDeliveryDate: this.order.details.predictedDeliveryDate ? new Date(this.order.details.predictedDeliveryDate) : null,
            predictedDeliveryTime: this.order.details.predictedDeliveryDate ? new Date(this.order.details.predictedDeliveryDate) : null,
        });
        this.changeReasonForm.setValue({
            changeReason: '',
        });
        this.disableDateForm(this.orderChangeForm);
        this.showChangeReasonDialog = false;
        this.unsubmittedFormChangeSubject.next(false && this.orderChangeForm.valid);
    }

    public generateDeliveryNote(): void {
        if (this.canGenerateDeliveryNote) this.orderService.getDeliveryNote(this.order.id);
        else {
            this.errorToastService.emitError({
                message: 'ORDER_DETAILS.MISSING_PERMISSIONS_GENERATE_DELIVERY_NOTE',
            });
        }
    }

    public sendComplaint(): void {
        if (this.canSendComplaint) this.isComplained = true;
    }

    public goToLinkedOrder(orderId: string): void {
        this.setNewOrderId.emit(orderId);
    }

    private getForm(): FormGroup {
        const formGroup: FormGroup = this.formBuilder.group({
            state: [[this.order.details.state]],
            predictedDeliveryDate: this.order.details.predictedDeliveryDate ? [new Date(this.order.details.predictedDeliveryDate)] : null,
            predictedDeliveryTime: this.order.details.predictedDeliveryDate ? [new Date(this.order.details.predictedDeliveryDate)] : null,
        });

        this.disableDateForm(formGroup);

        return formGroup;
    }

    private disableDateForm(formGroup: FormGroup): void {
        const dateControl: AbstractControl | null = formGroup.get('predictedDeliveryDate');
        const timeControl: AbstractControl | null = formGroup.get('predictedDeliveryTime');

        if (dateControl && timeControl) {
            if (this.isStateShippedOrRejectedAndNoAdmin()) {
                dateControl?.disable();
                timeControl.disable();
            } else {
                dateControl?.enable();
                timeControl?.enable();
            }
        }
    }

    private getChangeReasonForm(): FormGroup {
        return this.formBuilder.group({
            changeReason: [''],
        });
    }

    private setupButtonHandling(): void {
        this.submitButtonState$ = combineLatest([this.orderChangeProcessStateSubject, this.unsubmittedFormChangeSubject]).pipe(
            map((value: Array<boolean>) => {
                return !value[0] && value[1] && this.orderChangeForm.valid;
            })
        );

        this.resetButtonState$ = combineLatest([this.orderChangeProcessStateSubject, this.unsubmittedFormChangeSubject]).pipe(
            map((value: Array<boolean>) => {
                return !value[0] && value[1];
            })
        );
    }

    private fetchPermissions(): void {
        this.authQuery.user$.subscribe({
            next: (user: User) => {
                this.user = user !== undefined ? user : ({} as User);
                this.canChangeState = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_STATE') ? true : false;
                this.canChangePredictedDeliveryDate = this.user?.permissions?.find(
                    (permission: string) => permission === 'CAN_CHANGE_PREDICTED_DELIVERY_DATE'
                )
                    ? true
                    : false;
                this.canGenerateDeliveryNote = this.user?.permissions?.find((permission: string) => permission === 'CAN_GENERATE_DELIVERY_NOTE')
                    ? true
                    : false;
                // TODO: The name of the permission needs to be refactored
                this.canSendComplaint = this.user?.permissions?.find((permission: string) => permission === 'CAN_RETRIGGER_AN_ORDER_IMPORT')
                    ? true
                    : false;
                this.canChangeStateToRejected = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_STATE_TO_REJECTED')
                    ? true
                    : false;
                this.isAdmin = this.user?.memberOfGroups?.find((group: string) => group === 'ZEISS_CONTRIBUTER') ? true : false;
            },
        });
    }

    private getDateTime(): Date | undefined {
        const date: Date = this.orderChangeForm.value.predictedDeliveryDate as Date;
        const time: Date = this.orderChangeForm.value.predictedDeliveryTime as Date;
        if (!date || !time) return undefined;
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }

    private formatDate(date: Date): string | null {
        return this.datePipe.transform(date, 'MM-dd-yyyy');
    }

    private formatTime(date: Date): string | null {
        return this.datePipe.transform(date, 'HH:mm');
    }

    public isStateShippedOrRejectedAndNoAdmin(): boolean {
        return this.utilService.isStateShippedOrRejectedAndNoAdmin(this.order, this.isAdmin);
    }

    public getStateString(): string {
        const state: string = this.stateTranslationService.getValueTranslation(this.order.details.state);
        if (state) {
            return state;
        }
        return '';
    }
}
