import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { AuthService } from './common/services/auth.service';
import { ToastService } from './common/services/toast/toast.service';
import { ToastContent, ToastNotification } from './common/models/toastState.type';
import { ToastQuery } from './main/store/toast/toast.query';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public readonly title: string = 'smt-pdx-frontend';
    public isLoading: boolean = true;
    public errorToastStream$!: Observable<ToastContent | false>;
    public successToastStream$!: Observable<ToastContent | false>;

    constructor(
        readonly authService: AuthService,
        readonly errorToastService: ToastService,
        readonly translateService: TranslateService,
        private toastQuery: ToastQuery
    ) {}

    public ngOnInit(): void {
        if (!this.authService.hasValidToken()) {
            this.fetchValidIdToken();
        } else {
            this.authService.loginUser();
            this.isLoading = false;
        }
        this.subscribeToErrorSteam();
        this.subscribeToSuccessSteam();
        this.authService.tokenEventsLog();
    }

    private subscribeToErrorSteam(): void {
        this.errorToastStream$ = this.toastQuery.errorToast$.pipe(
            map((error: ToastNotification) => {
                return error.active && error.content ? this.translateErrorKeys(error.content) : false;
            })
        );
    }

    private subscribeToSuccessSteam(): void {
        this.successToastStream$ = this.toastQuery.successToast$.pipe(
            map((success: ToastNotification) => {
                return success.active && success.content ? this.translateErrorKeys(success.content) : false;
            })
        );
    }

    private translateErrorKeys(error: ToastContent): ToastContent {
        return {
            code: error.code,
            message: error.message ? this.translateService.instant(error.message) : undefined,
            headerText: error.headerText ? this.translateService.instant(error.headerText) : undefined,
        };
    }

    private fetchValidIdToken(): void {
        this.authService.tryRefreshToken().subscribe((tokenIsRefreshed: boolean) => {
            if (!tokenIsRefreshed) {
                this.authenticateUser();
                return;
            }
            this.isLoading = false;
        });
    }

    private authenticateUser() {
        this.authService.tryZeissIdLogin().subscribe(() => {
            this.authService.login();
            this.isLoading = false;
        });
    }

    public closeToast(): void {
        this.errorToastService.setHideSuccessToast(10);
        this.errorToastService.setHideErrorToast(10);
    }
}
