import { Component, Input } from '@angular/core';
import { Order } from 'src/app/common/models/order.type';

@Component({
  selector: 'app-order-context-data',
  templateUrl: './order-context-data.component.html',
  styleUrls: ['./order-context-data.component.scss']
})
export class OrderContextDataComponent {
  @Input() public order!: Order;
}
