<div *ngFor="let releaseNote of releaseNotes; last as isLast">
   <h4 class="headline">
      {{ 'HEADERBAR.RELEASENOTES.HEADLINE' | translate }} {{ releaseNote.date | date : 'dd.MM.yyyy' }}
   </h4>
   <div class="added-features">
      <h4 class="sub-headline">
         {{ 'HEADERBAR.RELEASENOTES.ADDED' | translate }}
      </h4>
      <div class="feature" *ngFor="let addedFeature of releaseNote.added">
         <h4 class="category-name">
            {{ addedFeature.category }}
         </h4>
         <ul>
            <li *ngFor="let note of addedFeature.notes">{{ note }}</li>
         </ul>
      </div>
   </div>
   <div class="bugfixes">
      <h4 class="sub-headline">
         {{ 'HEADERBAR.RELEASENOTES.FIXED' | translate }}
      </h4>
      <ul>
         <li *ngFor="let bugfix of releaseNote.fixed">{{ bugfix }}</li>
      </ul>
   </div>
   <hr class="release-note-divider" *ngIf="!isLast">
</div>