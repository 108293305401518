/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/common/services/auth.service';

@Component({
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    host: {
        class: 'h-100 w-100',
    },
})
export class UnauthorizedComponent{
    constructor(readonly router: Router, private authService: AuthService) {}

    public logout() {
        this.authService.logout();
    }
}
