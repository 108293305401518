<div class="form-container">
    <div class="button-bar-order-navigation">
        <div class="button-bar-item" *ngIf="this.order.parentId">
            <dx-button (click)="goToLinkedOrder(this.order.parentId)">
                {{ 'ORDER_DETAIL.INITIAL_ORDER' | translate }}
            </dx-button>
        </div>
        <div class="button-bar-item" *ngIf="this.order.childId">
            <dx-button (click)="goToLinkedOrder(this.order.childId)">
                {{ 'ORDER_DETAIL.NEW_ORDER' | translate }}
            </dx-button>
        </div>
    </div>
    <div class="form" [formGroup]="orderChangeForm" *ngIf="orderChangeForm">
        <div class="field-container">
            <div class="label">
                <label for="predictedDeliveryDate"> {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.DELIVERY_DATE' | translate }}:
                </label>
            </div>
            <div class="field">
                <dx-date-box #predictedDeliveryDate name="predictedDeliveryDate" class="date-box" formControlName="predictedDeliveryDate" type="date"
                    stylingMode="underlined" placeholder="DD.MM.YYYY" displayFormat="dd.MM.yyyy">
                </dx-date-box>
            </div>
        </div>
        <div class="field-container">
            <div class="label">
                <label for="predictedDeliveryTime"> {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.DELIVERY_TIME' | translate }}:
                </label>
            </div>
            <div class="field">
                <dx-date-box #predictedDeliveryTime name="predictedDeliveryTime" class="date-box" formControlName="predictedDeliveryTime" type="time"
                    stylingMode="underlined" placeholder="HH:mm" displayFormat="HH:mm">
                </dx-date-box>
            </div>
        </div>
        <div class="field-container">
            <div class="label">
                <label for="state"> {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.LABEL' | translate }}:</label>
            </div>
            <div class="field">
                <div *ngIf="!isStateShippedOrRejectedAndNoAdmin(); else showString">
                    <dx-select-box [dataSource]="stateSelectOptions" displayExpr="displayName" valueExpr="value"
                        formControlName="state"
                        placeholder="{{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.PLACEHOLDER' | translate }}"></dx-select-box>
                </div>
                <ng-template #showString>
                    <div class="field-string">
                        {{ getStateString() }}
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="field-container">
            <div class="label">
                <label for="desiredDeliveryDate"> {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.DESIRED_DELIVERY_DATE' | translate
                    }}: </label>
            </div>
            <div class="field-date">
                {{ order.desiredDeliveryDate | date : 'dd.MM.yyyy HH:mm' }}
            </div>
        </div>
        <div class="field-container">
            <div class="label">
                <label for="orderDate"> {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.ORDER_DATE' | translate }}: </label>
            </div>
            <div class="field-date">
                {{ order.orderDate | date : 'dd.MM.yyyy HH:mm' }}
            </div>
        </div>
    </div>
    <div class="button-bar" *ngIf="!this.isComplained">
        <ng-container *ngIf="this.canChangeState || this.canChangePredictedDeliveryDate">
            <div class="button-bar-item change-button">
                <dx-button (click)="submitOrderChange()"
                    [disabled]="(submitButtonState$ | async) === false || !this.canChangeState || (!this.canChangePredictedDeliveryDate && !this.isAdmin)">
                    <div *dxTemplate="let data of 'content'">
                        <dx-load-indicator class="button-indicator"
                            [visible]="(orderChangeProcessState$ | async) === true" height="20" width="20">
                        </dx-load-indicator>
                        {{ 'ORDER_DETAIL.CHANGE' | translate }}
                    </div>
                </dx-button>
            </div>

            <div class="button-bar-item reset-button">
                <dx-button (click)="resetForm()" [disabled]="(resetButtonState$ | async) === false">
                    {{ 'ORDER_DETAIL.CANCEL' | translate }}
                </dx-button>
            </div>

            <div class="button-bar-item" *ngIf="this.canGenerateDeliveryNote">
                <dx-button (click)="generateDeliveryNote()">
                    {{ 'ORDER_DETAIL.GENERATE_DELIVERY_NOTE' | translate }}
                </dx-button>
            </div>
        </ng-container>

        <div class="button-bar-item" *ngIf="this.canSendComplaint">
            <dx-button (click)="sendComplaint()">
                {{ 'ORDER_DETAIL.COMPLAIN' | translate }}
            </dx-button>
        </div>
        <div class="enter-change-reason" *ngIf="showChangeReasonDialog" [formGroup]="changeReasonForm">
            <p>{{ 'ORDER_DETAIL.CHANGE_REASON' | translate }}</p>
            <dx-text-area formControlName="changeReason" valueChangeEvent="keyup"></dx-text-area>
            <div class="change-reason-buttons">
                <dx-button (click)="sendChange()" [disabled]="this.changeReasonForm.value.changeReason.length <= 0"
                    class="submit-button">
                    {{ 'ORDER_DETAIL.SEND_CHANGES' | translate }}
                </dx-button>
                <dx-button (click)="resetForm()">
                    {{ 'ORDER_DETAIL.CANCEL' | translate }}
                </dx-button>
            </div>
        </div>
    </div>
    <div *ngIf="this.isComplained" class="is-complained-message-container">
        <dx-load-indicator height="20" width="20"></dx-load-indicator>
        <p class="is-complained-message">{{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.COMPLAIN_LOADING' | translate }}</p>
    </div>
</div>


<dx-popup [(visible)]="this.isComplained" [hideOnOutsideClick]="false"
    [dragEnabled]="false" height="450px" width="400px"
    [resizeEnabled]="false">
    <app-complaint-dialog [complainedOrder]="this.order" (closeDialog)="handleCloseEvent($event)"></app-complaint-dialog>
</dx-popup>