import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MeasurementData, Order, ProductCategory, RecipeData } from 'src/app/common/models/order.type';
import { OrderDetailService } from '../../services/order-detail.service';
import { OrderService } from 'src/app/main/services/order/order.service';
import { AuthQuery } from 'src/app/main/store/auth/auth.query';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { User } from 'src/app/common/models/user.type';

@Component({
    selector: 'app-recipe-card',
    templateUrl: './recipe-card.component.html',
    styleUrls: ['./recipe-card.component.scss'],
})
export class RecipeCardComponent implements OnChanges {
    @Input()
    public orderId!: string;

    public recipeData!: RecipeData;
    public actualRingThicknesses: string[] = [];
    public measurementData!: MeasurementData;

    public user!: User;
    public canChangeRingThicknesses: boolean = false;
    public order!: Order;

    constructor(
        readonly orderDetailService: OrderDetailService,
        readonly orderService: OrderService,
        readonly authQuery: AuthQuery,
        private errorToastService: ToastService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['orderId']) this.initView();
    }

    private initView(): void {
        this.orderDetailService.fetchOrderData().subscribe((order: Order) => this.order = order);
        this.orderDetailService.fetchRecipeData().subscribe((recipeData: RecipeData) => (this.recipeData = recipeData));

        this.orderDetailService.fetchMeasurementData().subscribe((measurementData: MeasurementData) => {
            this.measurementData = measurementData;
            this.measurementData.ringThicknessActual?.forEach((ringDepth: number, index: number) => {
                this.actualRingThicknesses[index] = ringDepth + '';
            });
        });
        this.fetchPermissions();
    }
    
    public isDelta(order: Order) : boolean {
        return order.productCategory == ProductCategory.Optikring || order.productCategory == ProductCategory.Erdteil;
    }
    
    public saveRingThicknesses(): void {
        if (this.canChangeRingThicknesses) {
            const convertThicknesses: number[] = [];
            this.actualRingThicknesses.forEach((thickness: string, index: number) => {
                if (thickness !== '') convertThicknesses[index] = parseFloat(thickness);
            });
            this.orderService.updateActualRingThicknesses(this.orderId, { ringThicknessActual: convertThicknesses } as MeasurementData);
        } else {
            this.errorToastService.emitError({
                message: 'ORDER_DETAILS.MISSING_PERMISSIONS_CHANGE_DOCUMENTS',
            });
        }
    }

    private fetchPermissions(): void {
        this.authQuery.user$.subscribe({
            next: (user: User) => {
                this.user = user !== undefined ? user : ({} as User);
                this.canChangeRingThicknesses = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_MEASUREMENT_DATA')
                    ? true
                    : false;
            },
        });
    }

    public disableSubmitButton(): boolean {
        let result: boolean = true;
        if (this.actualRingThicknesses.length !== this.measurementData.ringThicknessActual?.length) {
            result = false;
        } else
            this.measurementData.ringThicknessActual?.forEach((ringThickness: number, index: number) => {
                if (this.actualRingThicknesses[index] !== ringThickness.toString()) {
                    result = false;
                    return;
                }
            });
        return result;
    }
}
