<ng-container *ngIf="order">
    <div class="styled-border detail-container">
        <div class="context-data-container">
            <app-order-context-data-form [order]="order" [orderChangeIsPending]="orderChangeIsPending"
                (setNewOrderId)="setNewOrderId($event)"></app-order-context-data-form>
            <hr class="divider">
            <app-order-context-data [order]="order"></app-order-context-data>
            <hr class="divider">
        </div>
        <div class="index-cards-container">
            <dx-tab-panel width="100%" [dataSource]="tabPanelOptions" tabsPosition="top" itemTitleTemplate="titleTemplate">
                <div *dxTemplate="let value of 'titleTemplate'">
                    {{ value.displayName | translate }}
                </div>
                <div *dxTemplate="let tabPanelItem of 'item'">
                    <div *ngIf="orderId" class="styled-border content-card">
                        <ng-container *ngIf="tabPanelItem.value === 'recipeData'">
                            <app-recipe-card [orderId]="orderId"></app-recipe-card>
                        </ng-container>
                        <ng-container *ngIf="tabPanelItem.value === 'measurements'">
                            <app-measurement-card [orderId]="orderId"></app-measurement-card>
                        </ng-container>
                    </div>
                </div>
            </dx-tab-panel>

            <div *ngIf="!orderId" class="styled-border content-card">
                {{ 'ERROR.ORDER_DETAILS.MISSING_ORDERID' | translate }}
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="!order" class="loader-box styled-border">
    <dx-load-indicator height="80" width="80"></dx-load-indicator>
    <h4>{{ 'ORDER_DETAIL.LOADING' | translate }}</h4>
</div>