import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Order, OrderDetails } from 'src/app/common/models/order.type';
import { OrderDetailService } from './services/order-detail.service';
import { AuthQuery } from '../../store/auth/auth.query';
import { User } from 'src/app/common/models/user.type';
import { Location } from '@angular/common';

export interface TabPanelOption {
    displayName: string;
    value: string;
}

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnChanges {

    @Input()
    public orderId!: string | undefined;

    @Output() closeDetailsView: EventEmitter<void> = new EventEmitter<void>();

    public order!: Order | undefined;
    public orderChangeIsPending: boolean = false;
    public user!: User;
    public tabPanelOptions!: TabPanelOption[];

    constructor(
        readonly orderDetailService: OrderDetailService,
        readonly authQuery: AuthQuery,
        readonly location: Location
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        const newOrderId: string | undefined = changes['orderId'].currentValue;
        if(newOrderId) this.initView(newOrderId);
    }

    public onOrderChange(orderId: string, orderDetails: OrderDetails): void {
        this.orderChangeIsPending = true;
        this.orderDetailService.updateChangedOrder(orderId, orderDetails).subscribe(() => {
                this.orderChangeIsPending = false;
        });
    }

    public setNewOrderId(newOrderId: string): void {
        this.orderId = newOrderId;
        this.initView(newOrderId);
    }

    private initView(orderId: string): void {
        this.location.go(`/orders/order/${orderId}`);
        this.fetchOrder(orderId);
        this.fetchPermissions();
        this.initTabPanelOptions();
    }

    private initTabPanelOptions(): void {
        this.tabPanelOptions = [
            { displayName: 'ORDER_DETAIL.TAB_BAR.RECIPE_DATA', value: 'recipeData'},
            { displayName: 'ORDER_DETAIL.TAB_BAR.MEASUREMENTS', value: 'measurements'}
        ];
    }

    private fetchOrder(orderId: string): void {
        this.order = undefined;
        setTimeout(() => {
            this.orderDetailService
            .fetchOrderDetail(orderId)
            .subscribe((order: Order) => {
                if(Object.keys(order).length === 0) {
                    this.closeDetailsView.emit();
                }
                this.order = order;
            });
        }, 250);
    }

    private fetchPermissions(): void {
        this.authQuery.user$.subscribe({
            next: (user: User) => {
                this.user = user !== undefined ? user : {} as User;
            },
        });
    }
}