import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment';
import { ReleaseNotes } from '../../models/releaseNotes.type';

@Injectable({
    providedIn: 'root',
})
export class ReleaseNotesHttpService {
    constructor(private http: HttpClient) {}

    /**
     * Get ReleaseNotes
     *
     * @returns {Observable<ReleaseNotes[]>} the releasenotes until the given version
     */
    public getReleaseNotes(language: string): Observable<ReleaseNotes[]> {
        return this.http.get<ReleaseNotes[]>(ENVIRONMENT.ressourceEndpoint + `releasenote/${language}/${ENVIRONMENT.versionNumber}`);
    }
}
