import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import {
    DxAccordionModule,
    DxButtonModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxListModule,
    DxNumberBoxModule,
    DxPopoverModule,
    DxPopupModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxLoadIndicatorModule,
    DxMenuModule,
    DxTooltipModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTabPanelModule
} from 'devextreme-angular';
import { ENVIRONMENT } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderBarComponent } from './common/components/header/header-bar/header-bar.component';
import { LoginComponent } from './common/components/login/login.component';
import { DashboardComponent } from './main/components/dashboard/dashboard.component';
import { MeasurementCardComponent } from './main/components/order-detail/components/measurement-card/measurement-card/measurement-card.component';
import { OrderContextDataFormComponent } from './main/components/order-detail/components/order-context-data-form/order-context-data-form.component';
import { OrderContextDataComponent } from './main/components/order-detail/components/order-context-data/order-context-data.component';
import { RecipeCardComponent } from './main/components/order-detail/components/recipe-card/recipe-card.component';
import { OrderDetailComponent } from './main/components/order-detail/order-detail.component';
import { OrderDataGridComponent } from './main/components/orders-search/components/order-data-grid/order-data-grid.component';
import { OrderSearchBarComponent } from './main/components/orders-search/components/order-search-bar/order-search-bar.component';
import { OrderSearchComponent } from './main/components/orders-search/order-search.component';
import { AuthInterceptor } from './common/interceptor/auth.interceptor';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DatePipe } from '@angular/common';
import { UtilService } from './common/services/utils/utils.service';
import { ComplaintDialogComponent } from './common/components/complaint-dialog/complaint-dialog.component';
import { ErrorHandlerService } from './common/handler/error-handler.service';
import { LoggingService } from './common/services/logging.service';
import { OrderBulkEditComponent } from './main/components/orders-search/components/order-bulk-edit/order-bulk-edit.component';
import { ProductCategoryTranslationPipe } from './common/pipes/product-category-translation.pipe';
import { NewDeliveryDatePipe } from './common/pipes/new-delivery-date.pipe';
import { ChristmasGreetingsComponent } from './common/components/christmas-greetings/christmas-greetings.component';
import { UnitOfMeasurePipe } from "./common/pipes/unit-of-measure.pipe";
import { UserInformationComponent } from './common/components/header/user-information/user-information.component';
import { ReleaseNotesComponent } from './common/components/header/release-notes/release-notes.component';
import { UnauthorizedComponent } from './common/components/error-pages/unauthorized/unauthorized.component';

/**
 *  Initializes the TranslateHttpLoader
 *
 *  @param {HttpClient} http  a HttpClient  which is used to Query the Language-Json
 *  @returns {TranslateHttpLoader} TranslateHttpLoader Instance
 */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const oauthConfig: AuthConfig = {
    issuer: ENVIRONMENT.issuer,
    clientId: ENVIRONMENT.clientId,
    logoutUrl: ENVIRONMENT.end_session_endpoint,
    postLogoutRedirectUri: window.location.origin + '/login',
    redirectUri: window.location.origin + '/login',
    responseType: 'code',
    scope: 'openid profile email offline_access',
    customQueryParams: { client_secret: ENVIRONMENT.clientSecret },
    showDebugInformation: ENVIRONMENT.zeissIdDebug,
    strictDiscoveryDocumentValidation: false,
    disablePKCE: false,
    tokenEndpoint: ENVIRONMENT.tokenEndpoint,
    useSilentRefresh: true,
};

/**
 * Factory for OAuthStorage
 *
 * @returns {OAuthStorage} sessionStorage;
 */
export default function storageFactory(): OAuthStorage {
    return sessionStorage;
}

@NgModule({
    declarations: [
        ProductCategoryTranslationPipe,
        UnitOfMeasurePipe,
        NewDeliveryDatePipe,
        AppComponent,
        ComplaintDialogComponent,
        HeaderBarComponent,
        LoginComponent,
        DashboardComponent,
        OrderSearchComponent,
        OrderSearchBarComponent,
        OrderDataGridComponent,
        OrderDetailComponent,
        OrderContextDataComponent,
        OrderContextDataFormComponent,
        RecipeCardComponent,
        MeasurementCardComponent,
        OrderBulkEditComponent,
        ChristmasGreetingsComponent,
        UserInformationComponent,
        ReleaseNotesComponent,
        UnauthorizedComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        DxSwitchModule,
        DxTextBoxModule,
        DxDropDownBoxModule,
        DxListModule,
        DxToolbarModule,
        DxButtonModule,
        DxPopupModule,
        DxPopoverModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxAccordionModule,
        DxDataGridModule,
        DxLoadIndicatorModule,
        DxTooltipModule,
        DxMenuModule,
        DxCheckBoxModule,
        DxTagBoxModule,
        DxSelectBoxModule,
        DxTemplateModule,
        DxTextAreaModule,
        DxTabPanelModule,
        NgxFileDropModule,
        OAuthModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'de-DE',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AkitaNgRouterStoreModule,
    ],
    providers: [
        { provide: AuthConfig, useValue: oauthConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        DatePipe,
        UtilService,
        LoggingService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
