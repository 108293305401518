import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthStore } from './auth.store';
import { AuthState } from 'src/app/common/models/authState.type';
import { User } from 'src/app/common/models/user.type';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
    public authState$: Observable<AuthState> = this.select();

    public user$: Observable<User> =
        this.select('user');

    constructor(protected override store: AuthStore) {
        super(store);
    }

    get user(): User {
        return this.getValue().user;
    }
}
