import { Query } from '@datorama/akita';
import { OrderStore } from './order.store';
import { OrderState } from "../../../common/models/orderState.type";
import { Observable } from 'rxjs';
import { MeasurementDocument, Order, OrderSearch } from 'src/app/common/models/order.type';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OrderQuery extends Query<OrderState> {
    public orderState$: Observable<OrderState> = this.select();
    public detailedOrder$: Observable<Order | undefined> =
        this.select('detailOrder');
    public searchQuery$: Observable<OrderSearch | undefined> =
        this.select('searchQuery');
    public searchResultQuery$: Observable<Order[]> =
        this.select('orderList');
    public measurementDocuments$: Observable<MeasurementDocument[]> =
        this.select('measurementDocuments');

    constructor(protected override store: OrderStore) {
        super(store);
    }

    get detailedOrder(): Order | undefined {
        return this.getValue().detailOrder;
    }

    get orderList(): Order[] {
        return this.getValue().orderList;
    }

    get searchQuery(): OrderSearch | undefined {
        return this.getValue().searchQuery;
    }

    get measurementDocuments(): MeasurementDocument[] {
        return this.getValue().measurementDocuments;
    }
}
